import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { Checkbox } from 'antd';
import B2becTranslation from 'components/B2becTranslation';
import { RIGHT_CHECKBOX_STATUS } from 'libs/constants/statuses';
import { isValidArray } from 'libs/utils/array';
import { checkHasPropertiesInObject } from 'libs/utils/object';
import React from 'react';

import { trimWhiteSpaceInClusterName } from '../RoleForm/RightTable';

const renderColumns = () => [
  {
    title: '',
    width: '1%',
    dataIndex: 'isSelected',
    render: (text) => {
      switch (text) {
        case RIGHT_CHECKBOX_STATUS.indeterminate: {
          return (
            <Checkbox
              className="kaercher-checkbox"
              indeterminate
              checked={false}
            />
          );
        }
        case RIGHT_CHECKBOX_STATUS.checked: {
          return <Checkbox className="kaercher-checkbox" checked />;
        }
        case RIGHT_CHECKBOX_STATUS.unchecked: {
          return <Checkbox className="kaercher-checkbox" checked={false} />;
        }
        default:
          return <Checkbox className="kaercher-checkbox" checked={false} />;
      }
    },
  },
  {
    title: ({ sortOrder }) => (
      <>
        <B2becTranslation value="roleManagement.rightName" />
        {sortOrder === 'ascend' ? (
          <CaretUpOutlined style={{ marginLeft: 10 }} />
        ) : (
          <CaretDownOutlined style={{ marginLeft: 10 }} />
        )}
      </>
    ),
    width: '99%',
    sorter: (a, b) => {
      return (a?.clusterName || a?.rightId).localeCompare(
        b?.clusterName || b?.rightId
      );
    },
    sortDirections: ['ascend', 'descend', 'ascend'],
    render: (_, record) => {
      // const renderName = checkHasPropertiesInObject(record, 'clusterId')
      //   ? record.clusterName
      //   : record.rightId;

      if (checkHasPropertiesInObject(record, 'clusterId')) {
        const trimmedClusterName = trimWhiteSpaceInClusterName(
          record.clusterName
        );
        return (
          <span>
            <B2becTranslation value={`rights.${trimmedClusterName}.name`} />
          </span>
        );
      }

      const { rightId, clusterName } = record;
      const splittedRightId = rightId.split(':');

      if (isValidArray(splittedRightId)) {
        const rightId = splittedRightId[1];
        const trimmedClusterName = trimWhiteSpaceInClusterName(clusterName);

        return (
          <span>
            <B2becTranslation
              value={`rights.${trimmedClusterName}.${rightId}`}
            />
          </span>
        );
      }
    },
  },
];

export default renderColumns;

import { SAPDowntimeModal } from 'components/ConfigurationModal';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectIsCurrentTimeInSAPDowntime } from 'store/selectors/configSelector';

export default function SAPDownTime() {
  const isCurrentTimeInSAPDowntime = useSelector(
    selectIsCurrentTimeInSAPDowntime
  );

  const [shouldOpenSAPDowntimeModal, setShouldOpenSAPDowntimeModal] =
    useState(false);

  const handleCloseModal = () => {
    setShouldOpenSAPDowntimeModal(false);
  };

  useEffect(() => {
    if (isCurrentTimeInSAPDowntime) {
      setShouldOpenSAPDowntimeModal(true);
    }
  }, [isCurrentTimeInSAPDowntime]);

  return shouldOpenSAPDowntimeModal ? (
    <SAPDowntimeModal
      open={shouldOpenSAPDowntimeModal}
      onClose={handleCloseModal}
    />
  ) : null;
}

import { Empty, Grid, Input } from 'antd';
import clsx from 'clsx';
import B2becButton from 'components/B2becButton';
import B2becInfoLayout from 'components/B2becInfoLayout';
import B2becPagination from 'components/B2becPagination';
import { PlusIcon, SearchIcon } from 'components/Icons';
import CreateWishlistModal from 'components/Wishlist/CreateWishlistModal';
import { useDebounce, useDocumentTitle } from 'hooks';
import useAdobeAnalysis from 'hooks/useAdobeAnalysis';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import { ASYNC_STATUS } from 'libs/constants';
import { PAGE_TYPES } from 'libs/constants/adobeAnalytics';
import { isEmptyArray } from 'libs/utils/array';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserId } from 'store/selectors/authenticationSelectors';
import {
  selectFetchWishlistsState,
  selectIsDeletingWishlist,
  selectWishlistOverview,
} from 'store/selectors/wishlistSelectors';
import { getWishlistOverview } from 'store/slices/wishlistSlice';

import PermissionWrapper from '../../HOCs/permissionWrapper';
import { MODULE_WISHLIST_RIGHTS } from '../../libs/constants/modulerights';
import WishlistList from './WishlistList';
import styles from './WishlistOverview.module.scss';

const WishlistOverview = (props) => {
  const { title, breadcrumbs, pageId } = props;
  useDocumentTitle(title);
  useBreadcrumbs(breadcrumbs);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useAdobeAnalysis(null, {
    pageName: 'wishlist overview page',
    pageId,
    pageType: PAGE_TYPES.WISHLIST,
  });

  const ownerId = useSelector(selectUserId);
  const { items, pagination, updatedTime } = useSelector(
    selectWishlistOverview
  );
  const pageNumber = pagination?.currentPage || 1;
  const isLoading =
    useSelector(selectFetchWishlistsState) === ASYNC_STATUS.PENDING;
  const isDeletingWishlist = useSelector(selectIsDeletingWishlist);
  const { lg } = Grid.useBreakpoint();

  const [query, setQuery] = useState('');
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const searchQuery = useDebounce(query, 300);
  const handleChangeText = useCallback((text) => {
    setQuery(text);
  }, []);

  const getWishList = useCallback(
    (currentPage) => {
      if (!ownerId) {
        return;
      }
      dispatch(
        getWishlistOverview({
          ownerId,
          searchQuery,
          pageNumber: currentPage,
        })
      );
    },
    [dispatch, ownerId, searchQuery]
  );

  const isEmpty = isEmptyArray(items) && !isLoading;

  useEffect(() => {
    getWishList(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  useEffect(() => {
    getWishList(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedTime]);

  return (
    <B2becInfoLayout
      className={clsx(styles.wishlistOverview, lg && styles.desktop)}
    >
      <B2becInfoLayout.Title>
        <div className={styles.title}>
          <h1>{t('pageTitle.wishlistOverview')}</h1>
          <PermissionWrapper permission={MODULE_WISHLIST_RIGHTS.ADD_WISHLIST}>
            <div className={styles.titleActions}>
              {lg ? (
                <B2becButton
                  disabled={isDeletingWishlist}
                  onClick={() => setIsCreateModalVisible(true)}
                >
                  {t('wishlist.actions.addWishlist')}
                </B2becButton>
              ) : (
                <button
                  className={styles.addButton}
                  disabled={isDeletingWishlist}
                  type="button"
                  onClick={() => setIsCreateModalVisible(true)}
                >
                  <PlusIcon />
                </button>
              )}
            </div>
          </PermissionWrapper>
        </div>
      </B2becInfoLayout.Title>
      <PermissionWrapper permission={MODULE_WISHLIST_RIGHTS.VIEW_WISHLIST}>
        <B2becInfoLayout.Content>
          <div className={styles.searchInputContainer}>
            <Input
              placeholder={t('buttonTexts.search')}
              prefix={<SearchIcon className="user-management__search-icon" />}
              onChange={(e) => handleChangeText(e.target.value)}
              disabled={isDeletingWishlist}
            />
          </div>
          {isEmpty ? (
            <Empty description={t('noData')} />
          ) : (
            <>
              <WishlistList isLoading={isLoading} items={items} />
              <B2becPagination
                disabled={isLoading}
                pageSize={pagination?.pageSize}
                total={pagination?.totalCount}
                current={pagination?.currentPage}
                onPageChange={getWishList}
              />
            </>
          )}
          {isCreateModalVisible && (
            <PermissionWrapper permission={MODULE_WISHLIST_RIGHTS.ADD_WISHLIST}>
              <CreateWishlistModal
                open={isCreateModalVisible}
                onClose={() => setIsCreateModalVisible(false)}
              />
            </PermissionWrapper>
          )}
        </B2becInfoLayout.Content>
      </PermissionWrapper>
    </B2becInfoLayout>
  );
};

WishlistOverview.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default React.memo(WishlistOverview);

import './styles.scss';

import { Col, Modal, Row } from 'antd';
import { isValidArray } from 'libs/utils/array';
import { linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ReactComponent as CloseIconSVG } from '../../assets/icons/close.svg';
import { PRODUCT_COMPARISON } from '../../libs/constants/productTypes';
import CustomButton from '../CustomButton';
import ProductComparisonCard from './ProductComparisonCard';

function ProductComparisonModal(props) {
  const { showModal, setShowModal, productsData } = props;
  // productsData is current data pass to the modal
  const { t } = useTranslation();
  const history = useHistory();
  const [productList, setProductList] = useState(
    localStorage.getItem(PRODUCT_COMPARISON)
      ? JSON.parse(localStorage.getItem(PRODUCT_COMPARISON))
      : []
  );
  // const [productExtra, setProductExtra] = useState(
  //   localStorage.getItem(PRODUCT_COMPARISON_EXTRA)
  //     ? JSON.parse(localStorage.getItem(PRODUCT_COMPARISON_EXTRA))
  //     : null
  // );
  const [productExtra, setProductExtra] = useState(null);

  const handleOk = () => {
    setShowModal(false);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleToProductComparisonPage = () => {
    setShowModal(false);
    history.push(linkGenerator('/product-comparison'));
  };

  // Save Product Info to Local Storage
  const handleSaveProductComparison = (dataSrc) => {
    if (localStorage.getItem(PRODUCT_COMPARISON)) {
      const localProductComparison = JSON.parse(
        localStorage.getItem(PRODUCT_COMPARISON)
      );
      if (localProductComparison.length === 3) {
        // Already had 3 products on product comparison list case. If dataSrc is not in  product comparison list,
        // add it to product comparison extra
        if (
          !localProductComparison.some(
            (product) => product.materialNumber === dataSrc.materialNumber
          )
        ) {
          // localStorage.setItem(
          //   PRODUCT_COMPARISON_EXTRA,
          //   JSON.stringify(dataSrc)
          // );
          setProductExtra(dataSrc);
        }
      } else {
        // Product comparison has less then 3 products
        const newData = [...localProductComparison, dataSrc];

        // Remove duplicate data by checking materialName
        const filteredData = Array.from(
          new Map(newData.map((e) => [e.materialNumber, e])).values()
        );

        localStorage.setItem(PRODUCT_COMPARISON, JSON.stringify(filteredData));
        setProductList(filteredData);
      }
    } else {
      const newData = [];
      newData.push(dataSrc);
      localStorage.setItem(PRODUCT_COMPARISON, JSON.stringify(newData));
      setProductList(newData);
    }
  };

  // Remove one product comparison and add extra product comparison item to the list
  const removeProductComparisonItem = (materialNo) => {
    const newProductList = productList.filter(
      (product) => product.materialNumber !== materialNo
    );
    newProductList.push(productExtra);

    localStorage.setItem(PRODUCT_COMPARISON, JSON.stringify(newProductList));
    // localStorage.removeItem(PRODUCT_COMPARISON_EXTRA);
    setProductList(newProductList);
    setProductExtra(null);
  };

  // Render Modal Title
  const renderModalTitle = () => {
    if (isValidArray(productList)) {
      const totalProducts = productList.length;
      if (totalProducts === 1) {
        return t('productComparison.itemAdded');
      }
      if (totalProducts === 3) {
        // if (localStorage.getItem(PRODUCT_COMPARISON_EXTRA)) {
        if (productExtra) {
          return t('productComparison.fullProductTitle');
        }
      }
      return t('productComparison.selectedItemTotal', {
        total: totalProducts,
      });
    }
    return '';
  };

  // Render Product Card List
  const renderProductList = () => {
    if (isValidArray(productList)) {
      if (productExtra) {
        return (
          <ProductComparisonCard
            productList={productList}
            hasExtraProduct
            removeItemFunc={removeProductComparisonItem}
          />
        );
      }
      return <ProductComparisonCard productList={productList} />;
    }
    return '';
  };

  // Render Summary
  const renderSummaryContent = () => {
    if (productList.length === 3) {
      if (productExtra) {
        // const productComparisonExtra = JSON.parse(
        //   localStorage.getItem(PRODUCT_COMPARISON_EXTRA)
        // );
        const arrData = [];
        arrData.push(productExtra);
        return (
          <div
            className="product-comparison-modal__summary"
            style={{ marginTop: 30 }}
          >
            <ProductComparisonCard productList={arrData} />
            <Row>
              <Col xs={24} sm={24} md={12} lg={14} xl={14}>
                <p>{t('productComparison.fullProductTexts')}</p>
              </Col>
            </Row>
          </div>
        );
      }
      return (
        <div className="product-comparison-modal__summary">
          <Row>
            <Col xs={24} sm={24} md={12} lg={14} xl={14}>
              <h2>{t('productComparison.readyTitle')}</h2>
              <p>
                {t('productComparison.readyTexts', {
                  total: productList.length,
                })}
              </p>
            </Col>
          </Row>
        </div>
      );
    }
    return '';
  };

  // Modal Footer
  const renderFooterContent = () => {
    return (
      <Row>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <CustomButton
            type="ghost"
            className="cart-bottom__action__link--with-icon"
            onClick={() => setShowModal(false)}
          >
            {t('cart.continueShopping')}
          </CustomButton>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <CustomButton onClick={() => handleToProductComparisonPage()}>
            {t('productComparison.toProductComparison')}
          </CustomButton>
        </Col>
      </Row>
    );
  };

  // Init Modal
  useEffect(() => {
    if (showModal === true) {
      handleSaveProductComparison(productsData);
    } else {
      // clear extra slot when close the modal
      setProductExtra(null);
    }
  }, [showModal, productsData]);

  return (
    <Modal
      className={`product-comparison-modal${
        productList && productList.length > 1 ? ' multi' : ' single'
      }`}
      title={renderModalTitle()}
      open={showModal}
      width={700}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={renderFooterContent()}
      closeIcon={<CloseIconSVG />}
    >
      {renderProductList()}
      {renderSummaryContent()}
    </Modal>
  );
}

ProductComparisonModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  productsData: PropTypes.shape({}),
};

ProductComparisonModal.defaultProps = {
  showModal: false,
  setShowModal: () => {},
  productsData: {},
};

export default ProductComparisonModal;

import { LeftOutlined } from '@ant-design/icons';
import { Col, Empty, Row } from 'antd';
import B2becMatchingProducts from 'components/B2becMatchingProducts';
import MatchingProductsWithPaging from 'components/B2becMatchingProductsWithCategory';
import B2becPagination from 'components/B2becPagination';
import B2becSorting from 'components/B2BecSorting';
import { getSortOption } from 'components/B2BecSorting/sortOptions';
import CustomButton from 'components/CustomButton';
import NotFound from 'components/NotFound';
import B2becCardSkeleton from 'components/Skeletons/B2becCardSkeleton';
import { useBreadcrumbs, useDocumentTitle } from 'hooks';
import useAdobeAnalysis from 'hooks/useAdobeAnalysis';
import {
  PRODUCT_MATCHING_TYPES,
  SUITABLE_PRODUCTS_PER_PAGE,
} from 'libs/constants';
import { PAGE_TYPES } from 'libs/constants/adobeAnalytics';
import { linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  selectAccessoryProducts,
  selectDetergentProducts,
  selectHasFetchedMatchingProductsError,
  selectIsFetchingMatchingProducts,
  selectMaterialName,
  selectProductsOfAllTypes,
  selectSuitableProducts,
  selectTotalAccessoryProducts,
  selectTotalDetergentProducts,
  selectTotalSuitableProducts,
} from 'store/selectors/productSelector';
import { productActions } from 'store/slices/productSlice';

function ProductMatchingPage(props) {
  const { title, breadcrumbs, pageId } = props;

  const { materialNumber, type } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  useDocumentTitle(title);
  useBreadcrumbs(breadcrumbs, [{ pathData: [materialNumber, type] }]);

  const { setPageInfoData } = useAdobeAnalysis();

  const isFetchingMatchingProducts = useSelector(
    selectIsFetchingMatchingProducts
  );
  const materialName = useSelector(selectMaterialName);
  const accessoryProducts = useSelector(selectAccessoryProducts);
  const detergentProducts = useSelector(selectDetergentProducts);
  const suitableProducts = useSelector(selectSuitableProducts);
  const totalAccessoryProducts = useSelector(selectTotalAccessoryProducts);
  const totalDetergentProducts = useSelector(selectTotalDetergentProducts);
  const totalSuitableProducts = useSelector(selectTotalSuitableProducts);
  const productsOfAllTypes = useSelector(selectProductsOfAllTypes);
  const hasFetchedMatchingProductsError = useSelector(
    selectHasFetchedMatchingProductsError
  );
  const [shouldScrollToTop, setShouldSrollToTop] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOption, setSortOption] = useState(getSortOption('name.desc'));

  const handlePageChange = useCallback((pageNumber) => {
    setShouldSrollToTop(true);
    setCurrentPage(pageNumber);
  }, []);

  const handleSort = useCallback((order) => {
    setSortOption(order);
  }, []);

  const onShowAllHandler = (productType) => {
    history.push(linkGenerator(`/product/${materialNumber}/${productType}`));
  };

  let content = <Empty />;
  let header = null;

  useLayoutEffect(() => {
    if (shouldScrollToTop) {
      window.scrollTo(0, 0);
    }

    return () => setShouldSrollToTop(false);
  }, [shouldScrollToTop]);

  useEffect(() => () => productActions.clearMatchingProductsWhenUnmount(), []);

  useEffect(() => {
    // check invalid matching type
    if (!Object.values(PRODUCT_MATCHING_TYPES).includes(type)) {
      history.push('/404page');
      return;
    }

    dispatch(
      productActions.getMatchingProducts({
        productType: type,
        materialNumber,
        pageNumber: currentPage,
        sortOrder: sortOption.sortOrder,
      })
    );
  }, [
    type,
    history,
    dispatch,
    currentPage,
    materialNumber,
    sortOption.sortOrder,
  ]);

  useEffect(() => {
    const matchingType =
      type === PRODUCT_MATCHING_TYPES.ALL ? 'overview' : type;
    setPageInfoData({
      pageName: `products match:${materialNumber}:${matchingType}`,
      pageId,
      pageType: PAGE_TYPES.PRODUCT_MATCHING,
    });
  }, [setPageInfoData, materialNumber, pageId, type]);

  if (hasFetchedMatchingProductsError) {
    content = <NotFound />;
  }

  const renderProducts = (products = [], totalProducts = 0) => {
    header = (
      <>
        <div className="search-results__header__title">
          {t('searchResultsPage.title', {
            count: totalProducts,
          }).trim()}{' '}
          <q>{materialName}</q>
          {t(`searchResultsPage.header.${type}`)}
        </div>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={18}>
            <CustomButton
              buttonSize="small"
              onClick={() =>
                history.push(linkGenerator(`/product/${materialNumber}/all`))
              }
            >
              <LeftOutlined />
              {t('searchResultsPage.button.backToOverview')}
            </CustomButton>
          </Col>
          <Col
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={6}
            style={{ textAlign: 'right' }}
          >
            <B2becSorting sortOption={sortOption} onSort={handleSort} />
          </Col>
        </Row>
      </>
    );

    content = (
      <div className="search-results__content">
        {isFetchingMatchingProducts ? (
          <B2becCardSkeleton number={SUITABLE_PRODUCTS_PER_PAGE} />
        ) : (
          <MatchingProductsWithPaging products={products} />
        )}

        <B2becPagination
          hideOnSinglePage
          total={totalProducts}
          onPageChange={handlePageChange}
          current={currentPage}
        />
      </div>
    );
  };

  if (type === PRODUCT_MATCHING_TYPES.ALL) {
    return (
      <div className="container-full-width-page">
        <div className="search-results">
          <div className="search-results__header">
            <div className="search-results__header__title">
              {t('searchResultsPage.title', {}).trim()} <q>{materialName}</q>
            </div>
          </div>
          <B2becMatchingProducts
            materialNumber={materialNumber}
            dataSource={productsOfAllTypes}
            onShowAllHandler={onShowAllHandler}
          />
        </div>
      </div>
    );
  }

  if (type === PRODUCT_MATCHING_TYPES.ACCESSORIES) {
    renderProducts(accessoryProducts, totalAccessoryProducts);
  } else if (type === PRODUCT_MATCHING_TYPES.DETERGENTS) {
    renderProducts(detergentProducts, totalDetergentProducts);
  } else if (type === PRODUCT_MATCHING_TYPES.SUITABLE) {
    renderProducts(suitableProducts, totalSuitableProducts);
  }

  return (
    <div className="container-full-width-page">
      <div className="search-results">
        <div className="search-results__header">{header}</div>
        {content}
      </div>
    </div>
  );
}

ProductMatchingPage.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
  pageId: PropTypes.string.isRequired,
};

export default ProductMatchingPage;

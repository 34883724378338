const environment = {
  enableReduxDevTool: true,
  b2bDomain: 'https://dev.business.kaercher.com',
  loginUrl: 'https://login-dev.app.kaercher.com',
  baseUrl: 'https://api-dev.business.kaercher.com',
  shopFAPIUrl: 'https://shop-fapi.api-dev.business.kaercher.com/api',
  adminFAPIUrl: 'https://admin-fapi.api-dev.business.kaercher.com/api',
  serviceFAPIUrl: 'https://service-fapi.api-dev.business.kaercher.com/api',
  aws: {
    cognito: {
      userPoolId: 'eu-central-1_Trws0c7YS',
      oauthDomain: 'ak-b2bec-dev-users.auth.eu-central-1.amazoncognito.com',
      clientId: '5c4vcadgbsq8io6or11i4etlsf',
      region: 'eu-central-1',
      identityProvider: {
        customers: 'b2b-ec-customer',
        employees: 'b2b-ec-employee',
      },
    },
  },
};

export default environment;

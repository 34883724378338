import { Modal } from 'antd';
import React, { useCallback, useState } from 'react';

const useModal = () => {
  const [visible, setVisible] = useState(false);

  const render = useCallback(
    (props) => (
      <Modal open={visible} onClose={() => setVisible(false)} {...props} />
    ),
    [visible]
  );

  return {
    Modal: render,
    toggleModal: (value) => setVisible(value),
  };
};

export default useModal;

import { Checkbox, Divider, Form, Input, Modal } from 'antd';
import clsx from 'clsx';
import {
  ChevronLeftIcon,
  DeleteBorderlessIcon as DeleteIcon,
} from 'components/Icons';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { WishlistService } from 'services';

import { ReactComponent as CloseIconSVG } from '../../../assets/icons/close.svg';
import { useAsync, useDebounce } from '../../../hooks';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { STATUS_CODE } from '../../../libs/constants';
import BREAKPOINTS from '../../../libs/constants/breakpoints';
import { isValidArray } from '../../../libs/utils/array';
import { updateWishlistSharing } from '../../../store/slices/wishlistSlice';
import B2becButton from '../../B2becButton';
import B2becSearchSuggestion from '../../B2becSearchSuggestion';
import B2becSpinner from '../../B2becSpinner';
import { CustomFormItem } from '../../B2bUserForm';
import { NotificationManager } from '../../Notification';
import styles from './EditWishlistModal.module.scss';
import {
  getUserSuggestion,
  renameWishlist,
  shareWishlistToCompany,
  shareWishlistToUsers,
  widthdrawSharingWishlistToCompany,
} from './helper';
import SharingWishlistItem from './SharingWishlistItem';

const EditWishlistModal = (props) => {
  const { ownerId, wishlistId, shouldShowModal, setShouldShowModal } = props;
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const [isFetching, setIsFetching] = useState(true);
  const [isSubmiting, setIsSubmiting] = useState(false);

  const [searchText, setSearchText] = useState('');
  const searchQuery = useDebounce(searchText, 300);
  const [status, setStatus] = useState('idle');
  const [options, setOptions] = useState([]);
  const [forceResetAutocomplete, setForceResetAutocomplete] = useState();
  const initialValues = {
    name: '',
    collaborators: [],
    sharedWithCompany: false,
    ownerId: '',
    wishlistId: '',
  };
  const [currentValues, setCurrentValues] = useState(initialValues);
  const isMounted = useRef(null);
  const isLargerThan992 = useMediaQuery(`(min-width:${BREAKPOINTS.lg}px)`);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const [shouldDisableCollaboratorList, setShouldDisableCollaboratorList] =
    useState(initialValues?.sharedWithCompany);

  const getWishlistSharedDetail = useCallback(() => {
    return WishlistService.getWishlistSharedDetail(wishlistId);
  }, [wishlistId]);

  const { execute: excuteGetWishlistSharedDetail } = useAsync(
    getWishlistSharedDetail,
    true
  );

  useEffect(() => {
    excuteGetWishlistSharedDetail().then(({ response, error }) => {
      if (response?.status === STATUS_CODE.SUCCESS) {
        const wishlistItem = response?.data;
        const formValues = {
          name: wishlistItem?.wishlistName,
          collaborators: wishlistItem?.collaborators,
          sharedWithCompany: wishlistItem?.sharedWithCompany,
          ownerId: wishlistItem?.wishlistId,
          wishlistId: wishlistItem?.wishlistId,
        };
        form.setFieldsValue(formValues);
        setCurrentValues(formValues);
        setShouldDisableCollaboratorList(!!wishlistItem?.sharedWithCompany);
        setIsFetching(false);
      }
      if (error) {
        NotificationManager.error({
          message: 'notification.error.fetch',
        });
      }
    });
  }, [excuteGetWishlistSharedDetail, wishlistId, form]);

  const handleCancel = () => {
    setShouldShowModal(false);
  };

  const submitWishlist = async (values) => {
    const {
      name: newName,
      collaborators: newCollaborators,
      sharedWithCompany: newSharedWithCompany,
    } = values;

    const { name, collaborators, sharedWithCompany } = currentValues;

    const promises = [];

    if (name?.trim() !== newName?.trim()) {
      const renameWishlistPromise = renameWishlist({
        ownerId,
        wishlistId,
        newName: newName.trim(),
      });
      promises.push(renameWishlistPromise);
    }
    if (
      !newSharedWithCompany &&
      JSON.stringify(collaborators) !== JSON.stringify(newCollaborators)
    ) {
      const userIdList = newCollaborators.map((item) => item?.userId);
      const shareWishlistToUsersPromise = shareWishlistToUsers({
        wishlistId,
        userIds: userIdList,
      });
      promises.push(shareWishlistToUsersPromise);
    }
    if (newSharedWithCompany !== sharedWithCompany) {
      if (newSharedWithCompany) {
        const shareWishlistToCompanyPromise =
          shareWishlistToCompany(wishlistId);
        promises.push(shareWishlistToCompanyPromise);
      } else {
        const widthdrawSharingWishlistToCompanyPromise =
          widthdrawSharingWishlistToCompany(wishlistId);
        promises.push(widthdrawSharingWishlistToCompanyPromise);
      }
    }
    setIsSubmiting(true);
    const results = await Promise.allSettled(promises);
    const rejectedResponses = results
      .filter((result) => result.status === 'rejected')
      .map((result) => result.reason);
    if (isMounted) {
      setIsSubmiting(false);
      if (isValidArray(rejectedResponses)) {
        console.error('Errors: ', rejectedResponses);
        NotificationManager.success({
          message: 'notification.error.submit',
        });
      } else {
        setShouldShowModal(false);
        NotificationManager.success({
          message: 'notification.success.editWishlist',
        });
        dispatch(
          updateWishlistSharing({
            ownerId,
            wishlistId,
            wishlistName: newName,
            collaboratorCount: newCollaborators?.length,
            site: path.includes('wishlists') ? 'overview' : 'detail',
          })
        );
      }
    }
  };

  const handleSubmit = (values) => {
    if (isMounted) {
      setForceResetAutocomplete(new Date().toTimeString());
      submitWishlist(values);
    }
  };

  // Check if current collaborator array contain user object
  const checkContainsObject = (collaborarorArray, userObj) => {
    return collaborarorArray.some((item) => item?.userId === userObj?.userId);
  };

  const addItemtoCollaboratorList = useCallback(
    (user) => {
      const currentCollaboratorList = form.getFieldValue('collaborators');
      if (!checkContainsObject(currentCollaboratorList, user)) {
        form.setFieldsValue({
          collaborators: [...currentCollaboratorList, user],
        });
      }
    },
    [form]
  );

  const renderHeaderSection = () => {
    if (!isLargerThan992) {
      return (
        <>
          <div className={styles.title}>
            <button
              className="button-as-link"
              type="button"
              onClick={handleCancel}
            >
              <ChevronLeftIcon /> {t('buttonTexts.cancel')}
            </button>
            <button
              className="button-as-link"
              type="button"
              onClick={() => {
                form.submit();
              }}
            >
              {t('buttonTexts.save')}
            </button>
          </div>
          <Divider className={styles.divider} />
        </>
      );
    }
    return (
      <>
        <h1 className={styles.title}>
          {t('wishlist.modal.editWishlist.title')}
        </h1>
        <p>{t('wishlist.modal.editWishlist.description')}</p>
      </>
    );
  };

  const renderSearchItem = useCallback(
    (user) => {
      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div
          onClick={() => {
            addItemtoCollaboratorList(user);
          }}
        >
          {user?.email}
        </div>
      );
    },
    [addItemtoCollaboratorList]
  );

  const renderNotFoundContent = (searchTerm, searchStatus) => {
    if (!searchTerm) {
      return undefined;
    }

    if (searchStatus === 'pending') {
      return (
        <div style={{ width: '100%' }}>
          {`${t('searchFor')} "${searchTerm}"`}
          <Skeleton />
        </div>
      );
    }

    return <div>{`${t('searchNoResults')} "${searchTerm}"`}</div>;
  };

  useEffect(() => {
    async function fetchData() {
      const currentCollaboratorList = form.getFieldValue('collaborators');
      const response = await getUserSuggestion(searchQuery);
      const filteredResponse = response.filter(
        (item) =>
          item?.userId && !checkContainsObject(currentCollaboratorList, item)
      );
      const formattedResponse = filteredResponse.map((item) => ({
        label: renderSearchItem(item),
        value: item?.email,
        key: item?.userId,
      }));

      setOptions(formattedResponse);
      setStatus('idle');
    }
    fetchData();
  }, [form, renderSearchItem, searchQuery]);

  const handleSearch = async (text) => {
    setStatus('pending');
    setSearchText(text);
    setOptions([]);
  };

  return (
    <Modal
      width={isLargerThan992 ? 540 : '100%'}
      className={styles.model__wrapper}
      centered
      closable={isLargerThan992}
      destroyOnClose
      footer={null}
      open={shouldShowModal}
      closeIcon={<CloseIconSVG />}
      onCancel={handleCancel}
    >
      <B2becSpinner isLoading={isFetching} />
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        onFinish={handleSubmit}
      >
        {renderHeaderSection()}
        <CustomFormItem
          name="name"
          label="changeName"
          className={clsx('custom-form-item', styles.inputField)}
          rules={[
            {
              required: true,
              message: t('checkout.submitErrors.invalidMissingName'),
            },
          ]}
        >
          <Input placeholder={t('checkout.typeHere')} data-testid="name" />
        </CustomFormItem>
        <CustomFormItem
          name="sharedWithCompany"
          displayLabel={false}
          valuePropName="checked"
          onChange={(e) => {
            setShouldDisableCollaboratorList(e.target.checked);
          }}
        >
          <Checkbox>
            {t('wishlist.modal.editWishlist.everyOneCanAccessWishlist')}
          </Checkbox>
        </CustomFormItem>
        <div style={{ position: 'relative' }}>
          <div
            className={clsx(
              shouldDisableCollaboratorList && styles.disabledLayer
            )}
          />
          <div className={styles.label}>
            {t('wishlist.modal.editWishlist.inviteCollaborators')}
          </div>
          <B2becSearchSuggestion
            key={forceResetAutocomplete}
            options={options}
            onSearch={handleSearch}
            notFoundContent={renderNotFoundContent(searchText, status)}
            shouldShowRightButton={false}
            shouldDisableInput={shouldDisableCollaboratorList}
          />

          <div className={clsx(styles.list)}>
            {!shouldDisableCollaboratorList && (
              <Form.List
                name="collaborators"
                disabled={shouldDisableCollaboratorList}
              >
                {(fields, { remove }) => {
                  return (
                    <>
                      {fields.map((field) => {
                        return (
                          <div
                            key={`collaborators${field.name}`}
                            className={styles.wishlistItem}
                          >
                            <Form.Item
                              name={field.name}
                              {...field}
                              className={styles.wishlistItem_input}
                            >
                              <SharingWishlistItem
                                shouldDisableInput={
                                  shouldDisableCollaboratorList
                                }
                              />
                            </Form.Item>
                            <DeleteIcon
                              className={styles.wishlistItem_deleteButton}
                              onClick={() => {
                                if (!shouldDisableCollaboratorList) {
                                  remove(field.name);
                                }
                              }}
                            />
                          </div>
                        );
                      })}
                    </>
                  );
                }}
              </Form.List>
            )}
          </div>
        </div>

        {isLargerThan992 && (
          <div className={styles.footer}>
            <B2becButton onClick={handleCancel}>
              {t('buttonTexts.cancel')}
            </B2becButton>
            <B2becButton
              loading={isSubmiting}
              onClick={() => {
                form.submit();
              }}
            >
              {t('buttonTexts.save')}
            </B2becButton>
          </div>
        )}
      </Form>
    </Modal>
  );
};
export default EditWishlistModal;

import { Modal } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './B2becEnquiryModal.module.scss';

const B2becEnquiryModal = ({ children }) => {
  return (
    <Modal
      open
      className={styles.enquiryModule}
      closable={false}
      footer={null}
      width={570}
    >
      {children}
    </Modal>
  );
};

const B2becEnquiryModalFooter = (props) => {
  return <div className="enquiry-modal__footer">{props.children}</div>;
};

B2becEnquiryModal.propTypes = {
  children: PropTypes.node.isRequired,
};

B2becEnquiryModal.Footer = B2becEnquiryModalFooter;

export default B2becEnquiryModal;

import './styles.scss';

import { Modal } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CustomButton from '../../../components/CustomButton';

const EmptyCartConfirmModal = (props) => {
  const { isEmptyCartModalVisible, onEmptyCart, onCancel } = props;
  const { t } = useTranslation();

  return (
    <Modal
      open={isEmptyCartModalVisible}
      title={t('cart.modal.title')}
      onOk={onEmptyCart}
      onCancel={onCancel}
      footer={
        <div className="confirm-modal__footer">
          <CustomButton type="ghost" onClick={onCancel}>
            {t('cart.modal.cancelBtn')}
          </CustomButton>
          <CustomButton onClick={onEmptyCart}>
            {t('cart.modal.confirmBtn')}
          </CustomButton>
        </div>
      }
    >
      <span>{t('cart.modal.emptyCartContent')}</span>
    </Modal>
  );
};

EmptyCartConfirmModal.propTypes = {
  isEmptyCartModalVisible: PropTypes.bool,
  onEmptyCart: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

EmptyCartConfirmModal.defaultProps = {
  isEmptyCartModalVisible: false,
  onCancel: () => {},
};

export default EmptyCartConfirmModal;

import { MoreOutlined } from '@ant-design/icons';
import { Button, Popover } from 'antd';
import B2BecLink from 'components/B2BLink';
import ConfirmModal from 'components/ConfirmModal';
import EditWishlistModal from 'components/Wishlist/EditWishlistModal';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { deleteWishlist } from 'store/slices/wishlistSlice';

import PermissionWrapper from '../../HOCs/permissionWrapper';
import { MODULE_WISHLIST_RIGHTS } from '../../libs/constants/modulerights';
import styles from './WishlistItem.module.scss';

export const WishlistItemSkeleton = () => {
  return (
    <div className={styles.wishlistItem}>
      <div style={{ marginBottom: 23, padding: '0 24px' }}>
        <Skeleton width="100%" height={48} />
        <Skeleton width="100%" height={20} />
        <Skeleton width="100%" height={20} />
      </div>
      <div className={styles.productList}>
        <div className={styles.productItem}>
          <Skeleton height={98} width={98} />
        </div>
        <div className={styles.productItem}>
          <Skeleton height={98} width={98} />
        </div>
        <div className={styles.productItem}>
          <Skeleton height={98} width={98} />
        </div>
        <div className={styles.productItem}>
          <Skeleton height={98} width={98} />
        </div>
        <div className={styles.productItem}>
          <Skeleton height={98} width={98} />
        </div>
        <div className={styles.productItem}>
          <Skeleton height={98} width={98} />
        </div>
      </div>
    </div>
  );
};

const WishlistItem = (props) => {
  const {
    wishlistName,
    wishlistId,
    ownerId,
    isShared,
    ownerEmail,
    productCount,
    productPictures,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isDisplayDeleteModal, setDisplayDeleteModal] = useState(false);
  const [shouldDisplayEditModal, setShouldDisplayEditModal] = useState(false);
  const [isPopoverOptionsVisible, setIsPopoverOptionsVisible] = useState(false);
  const buttonRef = useRef();

  const wishlistDetailLink = useMemo(
    () => `/wishlist/${wishlistId}/owner/${ownerId}`,
    [wishlistId, ownerId]
  );

  const handleEditWishlist = (event) => {
    event.stopPropagation();
    setIsPopoverOptionsVisible(false);
    setShouldDisplayEditModal(true);
    setDisplayDeleteModal(false);
  };

  const handleDeleteWishlist = useCallback(() => {
    if (wishlistId && ownerId) {
      dispatch(
        deleteWishlist({
          wishlistId,
          wishlistOwnerId: ownerId,
        })
      );
    }
  }, [wishlistId, ownerId, dispatch]);

  const handleDelete = useCallback((e) => {
    e.stopPropagation();
    setIsPopoverOptionsVisible(false);
    setDisplayDeleteModal(true);
  }, []);

  const handleOnMouseLeave = () => {
    setIsPopoverOptionsVisible(false);
    if (buttonRef.current?.blur) {
      buttonRef.current.blur();
    }
  };

  const handleVisibleChange = (event) => {
    event.stopPropagation();
    setIsPopoverOptionsVisible(true);
  };

  return (
    <>
      <B2BecLink to={wishlistDetailLink} onMouseLeave={handleOnMouseLeave}>
        <div className={styles.wishlistItem}>
          <div className={styles.header}>
            <div className={styles.wishlistItemInfo}>
              <h3>{wishlistName}</h3>
              {isShared && (
                <>
                  <span className={styles.infoText}>
                    {t('wishlist.shared')}
                  </span>
                  {ownerEmail && (
                    <span className={styles.infoText}>{`${t(
                      'wishlist.owner'
                    )}: ${ownerEmail}`}</span>
                  )}
                </>
              )}
              <span className={styles.infoText}>
                {t('wishlist.productCount', { count: productCount })}
              </span>
            </div>

            {!isShared && (
              <Popover
                open={isPopoverOptionsVisible}
                overlayClassName={styles.overlay}
                placement="bottomRight"
                content={
                  <div
                    className={styles.wishlistActions}
                    onMouseLeave={handleOnMouseLeave}
                  >
                    <ul>
                      <PermissionWrapper
                        permission={MODULE_WISHLIST_RIGHTS.EDIT_SHARE_WISHLIST}
                      >
                        <li
                          className={styles.action}
                          onClick={handleEditWishlist}
                        >
                          {t('wishlist.actions.edit')}
                        </li>
                        <li
                          className={styles.action}
                          onClick={handleEditWishlist}
                        >
                          {t('wishlist.actions.share')}
                        </li>
                      </PermissionWrapper>
                      <PermissionWrapper
                        permission={MODULE_WISHLIST_RIGHTS.DELETE_WISHLIST}
                      >
                        <li className={styles.action} onClick={handleDelete}>
                          {t('wishlist.actions.delete')}
                        </li>
                      </PermissionWrapper>
                    </ul>
                  </div>
                }
                trigger={['click']}
              >
                <Button
                  shape="circle"
                  icon={<MoreOutlined />}
                  size="large"
                  onClick={handleVisibleChange}
                  ref={buttonRef}
                />
              </Popover>
            )}
          </div>
          <div className={styles.productList}>
            {productPictures
              .filter((img) => !!img)
              .map((img) => {
                return (
                  <div key={img} className={styles.productItem}>
                    <img src={img} alt="" height={98} width={98} />
                  </div>
                );
              })}
          </div>
        </div>
      </B2BecLink>

      <ConfirmModal
        isShowModal={isDisplayDeleteModal}
        setIsShowModal={setDisplayDeleteModal}
        modalTitle={t('wishlist.modal.deleteWishlist.title')}
        modalContent={t('wishlist.modal.deleteWishlist.modalContent')}
        confirmActionHandler={handleDeleteWishlist}
      />

      {shouldDisplayEditModal && (
        <EditWishlistModal
          ownerId={ownerId}
          wishlistId={wishlistId}
          shouldShowModal={shouldDisplayEditModal}
          setShouldShowModal={setShouldDisplayEditModal}
        />
      )}
    </>
  );
};

WishlistItem.defaultProps = {
  ownerEmail: '',
};

WishlistItem.propTypes = {
  wishlistName: PropTypes.string.isRequired,
  wishlistId: PropTypes.string.isRequired,
  ownerId: PropTypes.string.isRequired,
  productCount: PropTypes.number.isRequired,
  productPictures: PropTypes.arrayOf(PropTypes.string).isRequired,
  isShared: PropTypes.bool.isRequired,
  ownerEmail: PropTypes.string,
};

export default React.memo(WishlistItem);

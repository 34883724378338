import {
  InProgressingUserModal,
  MissingDataUserModal,
  UnauthorizedUserModal,
} from 'components/ConfigurationModal';
import { USER_STATUS } from 'libs/constants/user';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectUserStatusLowerCase } from 'store/selectors/userSelector';
import { selectShouldShowMissingDataUserModal } from 'store/slices/setupSlice';

function withCheckingUserStatus(WrappedComponent) {
  return (props) => {
    const userStatus = useSelector(selectUserStatusLowerCase);
    const isUserUnauthorized = userStatus === USER_STATUS.UNAUTHORIZED;
    const isUserInProgress = userStatus === USER_STATUS.IN_PROGRESS;
    const isUserMissingDataFromSAP = useSelector(
      selectShouldShowMissingDataUserModal
    );

    if (isUserMissingDataFromSAP) {
      return <MissingDataUserModal open />;
    }

    if (isUserUnauthorized) {
      return <UnauthorizedUserModal open />;
    }

    if (isUserInProgress) {
      return <InProgressingUserModal open />;
    }

    return <WrappedComponent {...props} />;
  };
}

export default withCheckingUserStatus;

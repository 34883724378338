import { Menu } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  selectEquipmentManagerLink,
  selectShouldRenderServiceMenu,
} from 'store/selectors/configSelector';

import usePermission from '../../../hooks/usePermission';
import { MODULE_SHOP_NAVIGATION_RIGHTS } from '../../../libs/constants/modulerights';
import ServiceSubmenu from '../ServicesMenu';
import HeaderSubmenu from '../Submenu';

function HeaderMenu() {
  const { t } = useTranslation();
  const [showProductMenu, setShowProductMenu] = useState(false);
  const [showServiceMenu, setShowServiceMenu] = useState(false);

  const toggleSubmenu = (menuState, setMenuState) => {
    window.scrollTo({ top: 0 });
    setMenuState(!menuState);
  };

  const equipmentManagerLink = useSelector(selectEquipmentManagerLink);
  const shouldRenderServiceMenu = useSelector(selectShouldRenderServiceMenu);
  const { hasPermission: canViewCategoriesList } = usePermission(
    MODULE_SHOP_NAVIGATION_RIGHTS.USE_PRODUCT_NAVIGATION
  );

  const items = [
    {
      key: 'product-navigation',
      label: (
        <button
          className={
            showProductMenu ? 'toggle-submenu-btn active' : 'toggle-submenu-btn'
          }
          type="button"
          onClick={() => {
            canViewCategoriesList &&
              toggleSubmenu(showProductMenu, setShowProductMenu);
          }}
        >
          {t('product')}
        </button>
      ),
    },
    shouldRenderServiceMenu && {
      key: 'service-menu',
      label: (
        <button
          className={
            showServiceMenu ? 'toggle-submenu-btn active' : 'toggle-submenu-btn'
          }
          type="button"
          onClick={() => toggleSubmenu(showServiceMenu, setShowServiceMenu)}
        >
          {t('services')}
        </button>
      ),
    },
    !!equipmentManagerLink && {
      key: 'equipment-manager',
      label: (
        <a
          className="toggle-submenu-btn"
          href={equipmentManagerLink}
          type="button"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('equipmentManagement')}
        </a>
      ),
    },
  ];

  return (
    <div className="menu-top-level__wrapper">
      <Menu mode="horizontal" className="header-nav" items={items} />
      {showProductMenu && <HeaderSubmenu setShowSubmenu={setShowProductMenu} />}
      {showServiceMenu && (
        <ServiceSubmenu setShowSubmenu={setShowServiceMenu} />
      )}
    </div>
  );
}

export default HeaderMenu;

import { Col, Form, Input, Row, Typography } from 'antd';
import B2becAssignRole from 'components/B2becAssignRole';
import { CustomFormItem } from 'components/B2bUserForm';
import B2bSalutation from 'components/B2bUserForm/B2bSalutation';
import B2bSelectLanguage from 'components/B2bUserForm/B2bSelectLanguage';
import B2bSelectSalesOrg from 'components/B2bUserForm/B2bSelectSalesOrg';
import PunchoutTypeSelection from 'components/PunchoutTypeSelection';
import ShoppingCartType from 'components/ShoppingCartType';
import { USER_FORM_TYPES } from 'libs/constants';
import { PUNCHOUT_TYPES } from 'libs/constants/punchoutTypes';
import {
  validatePasswordRequirements,
  validateSharedSecretRequirements,
} from 'libs/utils/passwordValidators';
import UserStatusCheckbox from 'pages/Admin/InviteNewUserPage/components/UserStatusCheckbox';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CustomButton from '../CustomButton';

const { useForm } = Form;

export const B2bTechnicalUserForm = ({
  errorMessage,
  onCancel,
  isUpdating,
  initialValues,
  type,
  submitFunc,
  form: formProp,
  ...props
}) => {
  const [form] = useForm(formProp);
  const { t } = useTranslation();

  const [punchoutType, setPunchoutType] = useState(PUNCHOUT_TYPES.OCI);

  const isHidden = type !== USER_FORM_TYPES.CREATE;

  const renderUserNameField = () => {
    return (
      <Col span={24} hidden={isHidden}>
        <CustomFormItem name="username" rules={[{ required: true }]}>
          <Input />
        </CustomFormItem>
      </Col>
    );
  };

  const renderPasswordField = () => {
    if (isHidden) return null;
    return (
      <Col span={24}>
        <CustomFormItem
          name="password"
          rules={[
            { required: true },
            {
              validator: validatePasswordRequirements,
            },
          ]}
        >
          <Input.Password autoComplete="off" />
        </CustomFormItem>
      </Col>
    );
  };

  const renderProcurementFields = useCallback(() => {
    if (punchoutType === PUNCHOUT_TYPES.CXML) {
      return (
        <>
          <Col span={24} hidden={isHidden}>
            <CustomFormItem
              name="networkId"
              label="networkId"
              rules={[{ required: true }]}
            >
              <Input />
            </CustomFormItem>
          </Col>
          <Col span={24} hidden={isHidden}>
            <CustomFormItem
              name="sharedSecret"
              label="sharedSecret"
              rules={[
                { required: true },
                {
                  validator: validateSharedSecretRequirements,
                },
              ]}
            >
              <Input.Password autoComplete="off" />
            </CustomFormItem>
          </Col>
        </>
      );
    }
    return null;
  }, [punchoutType, isHidden]);

  return (
    <>
      {errorMessage && (
        <Typography.Text type="danger">
          {t('userManagement.messages.editUserError')}
        </Typography.Text>
      )}
      <Form
        key={initialValues?.countryId}
        layout="vertical"
        form={form}
        initialValues={{ sessionBasedShoppingCart: true, ...initialValues }}
        onFinish={submitFunc}
        {...props}
      >
        <Row>
          <Col span={24}>
            <Row gutter={24}>
              <Col span={12}>
                <CustomFormItem name="punchOutType">
                  <PunchoutTypeSelection
                    isDisabled={type !== USER_FORM_TYPES.CREATE}
                    onChange={setPunchoutType}
                  />
                </CustomFormItem>
              </Col>
              <Col span={12} />
              <Col span={12}>
                <CustomFormItem name="salutation">
                  <B2bSalutation />
                </CustomFormItem>
              </Col>
              <Col span={12} />
              <Col span={12}>
                <CustomFormItem name="firstName" rules={[{ required: true }]}>
                  <Input />
                </CustomFormItem>
              </Col>
              <Col span={12}>
                <CustomFormItem name="lastName" rules={[{ required: true }]}>
                  <Input />
                </CustomFormItem>
              </Col>
              {renderUserNameField()}
              <Col span={24}>
                <CustomFormItem
                  name="email"
                  label="contactEmail"
                  rules={[{ required: true }, { type: 'email' }]}
                >
                  <Input />
                </CustomFormItem>
              </Col>
              {renderPasswordField()}
              {renderProcurementFields()}
              <Col span={12}>
                <CustomFormItem
                  name="phoneNumber"
                  label="contactNumber"
                  rules={[{ required: true }]}
                >
                  <Input />
                </CustomFormItem>
              </Col>
              <Col span={12}>
                <CustomFormItem
                  name="customerNumber"
                  label="sapCustomerNumber"
                  rules={[{ required: true }]}
                >
                  <Input />
                </CustomFormItem>
              </Col>
              <Col span={12}>
                <Form.Item
                  className="custom-form-item"
                  label={t('form.label.salesOrg')}
                  name="countryId"
                  rules={[
                    {
                      required: true,
                      message: t('form.validate.required', {
                        label: 'salesOrg',
                      }),
                    },
                  ]}
                >
                  <B2bSelectSalesOrg disabled={type === USER_FORM_TYPES.EDIT} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <CustomFormItem name="language" rules={[{ required: true }]}>
                  <B2bSelectLanguage />
                </CustomFormItem>
              </Col>
              {type === USER_FORM_TYPES.EDIT && <B2becAssignRole />}
              <Col span={24}>
                <CustomFormItem
                  name="status"
                  label="userStatus"
                  rules={[{ required: true }]}
                >
                  <UserStatusCheckbox isDisabled={false} />
                </CustomFormItem>
              </Col>
              <Col span={24}>
                <CustomFormItem
                  name="sessionBasedShoppingCart"
                  rules={[{ required: true }]}
                >
                  <ShoppingCartType isDisabled={false} />
                </CustomFormItem>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col>
            <Form.Item noStyle>
              <CustomButton type="ghost" onClick={onCancel}>
                {t('buttonTexts.cancel')}
              </CustomButton>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item noStyle>
              <CustomButton htmlType="submit" disabled={isUpdating}>
                {t('buttonTexts.saveChanges')}
              </CustomButton>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

B2bTechnicalUserForm.propTypes = {
  form: PropTypes.shape({}),
  initialValues: PropTypes.shape({
    countryId: PropTypes.string,
  }),
  isUpdating: PropTypes.bool,
  errorMessage: PropTypes.node,
  submitFunc: PropTypes.func,
  onCancel: PropTypes.func,
  type: PropTypes.oneOf([USER_FORM_TYPES.CREATE, USER_FORM_TYPES.EDIT]),
};

B2bTechnicalUserForm.defaultProps = {
  form: undefined,
  initialValues: {},
  isUpdating: false,
  errorMessage: '',
  submitFunc: () => {},
  onCancel: undefined,
  type: USER_FORM_TYPES.CREATE,
};

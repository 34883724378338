import './styles.scss';

import { Form } from 'antd';
import Search from 'antd/lib/input/Search';
import B2becPagination from 'components/B2becPagination';
import B2becTable from 'components/B2becTable';
import ConfigurationModal from 'components/ConfigurationModal';
import { SearchIcon } from 'components/Icons';
import { generateDummy } from 'hooks/useDummyItems';
import { DEFAULT_CONFIG_SCOPE } from 'libs/constants/configs';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './ConfigurationTable.module.scss';
import EditableCell from './editableCell';
import useRenderColumns from './renderColumns';

const configShape = {
  isInherit: false,
  name: 'Technical_User_Name',
  dataType: 'String',
  qualifier: '1',
  value: 'Max Mustermann',
  lastChanged: '2021-09-07T14:04:38.032+00:00',
  updatedBy: '29d629ff-8aa5-4f21-ac0d-768996be1002',
  group: 'SAP',
  description: 'Technical_User_Name',
  createdOn: '2021-09-07T14:04:38.032+00:00',
};

const dummyData = generateDummy(6, configShape, 'name');

function ConfigurationTable(props) {
  const {
    isFetching,
    configs,
    onEditConfig,
    onResetConfig,
    form,
    shouldShowResetButton,
    canUpdateConfiguration,
    scope,
    isShowSearch,
  } = props;

  const rowKey = 'name';
  const [displayWarningModal, shouldDisplayWarningModal] = useState(false);
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');

  const { columns } = useRenderColumns(
    isFetching,
    onEditConfig,
    onResetConfig,
    form,
    shouldShowResetButton,
    canUpdateConfiguration,
    configs,
    scope,
    displayWarningModal,
    shouldDisplayWarningModal
  );

  const handleChangeSearchText = useCallback((e) => {
    setSearchText(e.target.value);
    setCurrentPage(1);
  }, []);

  const filteredConfigs = useMemo(() => {
    if (searchText) {
      return configs?.filter((config) =>
        config.name.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return configs;
  }, [configs, searchText]);

  const dataSource = useMemo(() => {
    if (isFetching) {
      return dummyData;
    }

    return filteredConfigs?.slice((currentPage - 1) * 10, currentPage * 10);
  }, [currentPage, filteredConfigs, isFetching]);

  return (
    <>
      {isShowSearch && (
        <div className={styles.SearchContainer}>
          <Search
            bordered
            className="custom-search-input"
            size="large"
            placeholder={t('searchFor')}
            prefix={<SearchIcon />}
            value={searchText}
            onChange={handleChangeSearchText}
            enterButton={t('buttonTexts.search')}
          />
        </div>
      )}
      <Form form={form}>
        <B2becTable
          className="configuration-management__table__container"
          dataSource={dataSource}
          rowKey={rowKey}
          columns={columns}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          scroll={{ x: 640 }}
        />
      </Form>
      <B2becPagination
        total={filteredConfigs?.length}
        pageSize={10}
        current={currentPage}
        onPageChange={setCurrentPage}
      />
      <ConfigurationModal
        open={displayWarningModal}
        title={t('configurationManagement.modal.title')}
        content={<p>{t('configurationManagement.modal.description')}</p>}
        okText={t('buttonTexts.save')}
        onConfirmHandler={() => shouldDisplayWarningModal(false)}
      />
    </>
  );
}

ConfigurationTable.propTypes = {
  isFetching: PropTypes.bool,
  configs: PropTypes.arrayOf(PropTypes.shape({})),
  onEditConfig: PropTypes.func,
  onResetConfig: PropTypes.func,
  form: PropTypes.shape({}),
  shouldShowResetButton: PropTypes.bool,
  scope: PropTypes.shape({}),
};

ConfigurationTable.defaultProps = {
  isFetching: false,
  configs: [],
  onEditConfig: () => {},
  onResetConfig: () => {},
  form: {},
  shouldShowResetButton: false,
  scope: DEFAULT_CONFIG_SCOPE,
};

export default ConfigurationTable;

import './styles.scss';

import { LoadingOutlined } from '@ant-design/icons';
import { Col, Modal, Row } from 'antd';
import B2becTable from 'components/B2becTable';
import CustomButton from 'components/CustomButton';
import FormatStatus from 'components/FormatStatus';
import { NotificationManager } from 'components/Notification';
import useAsync from 'hooks/useAsync';
import useDisableBodyScroll from 'hooks/useDisableBodyScroll';
import { ASYNC_STATUS } from 'libs/constants';
import { hasEmptyElements } from 'libs/utils/array';
import { formatDate, getFormatType } from 'libs/utils/formatDate';
import {
  downloadMultipleInvoicesZipFile,
  getInvoices,
} from 'libs/utils/PDFHandler';
import { isStringNotEmpty } from 'libs/utils/stringUltis';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { orderServiceFAPI } from 'services';

import invoiceData from './invoiceData';
import tableColumn from './tableColumns';

function DownloadModal(props) {
  const {
    showModal,
    setShowModal,
    orderNumber,
    orderDate,
    referenceNumber,
    orderStatus,
    hasDownloadInvoicePermission,
  } = props;

  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState(invoiceData);
  const [selectedInvoices, setSelectedInvoices] = useState([]);

  useDisableBodyScroll(showModal);

  useEffect(() => {
    const getProductList = async () => {
      try {
        const { status, data } =
          await orderServiceFAPI.getProductsListByOrderNumber(orderNumber);

        if (status === 200) {
          setTableData(
            data.map((item, index) => ({
              index,
              ...item,
            }))
          );
        } else {
          NotificationManager.error({
            message: 'notification.error.loadInvoicesInfo',
          });

          setTableData([]);
        }
      } catch (error) {
        NotificationManager.error({
          message: 'notification.error.loadInvoicesInfo',
        });

        setTableData([]);
      } finally {
        setIsLoading(false);
      }
    };

    getProductList();
  }, [orderNumber]);

  const renderTitle = () => (
    <div className="invoices-download-modal__title">
      {t('orders.history.invoiceDownloadModal.title')}
    </div>
  );

  const renderOrderNumber = () => (
    <div className="invoices-download-modal__order-number">
      <p>
        {t('orders.history.invoiceDownloadModal.orderNumber.title')}{' '}
        {isLoading ? <Skeleton width={90} /> : orderNumber || 'N/A'}
      </p>
      <p>{t('orders.history.invoiceDownloadModal.orderNumber.description')}</p>
    </div>
  );

  const renderOrderDate = () => (
    <div className="invoices-download-modal__order-date">
      <p>{t('orders.history.invoiceDownloadModal.orderDate')}</p>
      <p>
        {isLoading ? (
          <Skeleton />
        ) : (
          formatDate(orderDate, getFormatType().default) || 'N/A'
        )}
      </p>
    </div>
  );

  const renderReferenceNumber = () => (
    <div className="invoices-download-modal__reference-number">
      <p>{t('orders.history.invoiceDownloadModal.referenceNumber')}</p>
      <p>{isLoading ? <Skeleton /> : referenceNumber || 'N/A'}</p>
    </div>
  );

  const renderStatus = () => (
    <div className="invoices-download-modal__status">
      <p>{t('orders.history.invoiceDownloadModal.status')}</p>
      <p>
        {isLoading ? (
          <Skeleton width={90} />
        ) : (
          <FormatStatus status={orderStatus} />
        )}
      </p>
    </div>
  );

  const onCheckboxChange = (checked, invoiceNumber) => {
    const index = tableData.findIndex(
      (invoice) => invoice.invoiceNumber === invoiceNumber
    );
    const newSelectedInvoice = [...selectedInvoices].filter(
      (invoice) => invoice !== invoiceNumber
    );
    if (checked) {
      newSelectedInvoice.push(invoiceNumber);
    }
    setSelectedInvoices(newSelectedInvoice);

    tableData[index].checked = checked;
    setTableData([...tableData]);
  };

  const renderInvoiceTable = () => (
    <B2becTable
      className="invoices-download-modal__table"
      columns={tableColumn(isLoading, onCheckboxChange)}
      dataSource={tableData}
      style={{ maxHeight: '40vh', marginBottom: 9 }}
      pagination={false}
      rowKey={({ index, invoiceNumber, products }) =>
        `${index}-${invoiceNumber}-${products
          .map((item) => item.materialNumber)
          .join('-')}`
      }
      boxShadow={false}
    />
  );

  const { execute, status, value } = useAsync(
    () => getInvoices(selectedInvoices),
    false
  );

  const isDownloading = status === ASYNC_STATUS.PENDING;

  useEffect(() => {
    if (status === ASYNC_STATUS.SUCCESS) {
      const dataArrayToDownload = Array.isArray(value?.data)
        ? value.data.filter(
            (item) =>
              isStringNotEmpty(item.fileName) &&
              isStringNotEmpty(item.file) &&
              isStringNotEmpty(item.contentType)
          )
        : [];
      if (dataArrayToDownload.length > 0 && hasDownloadInvoicePermission) {
        downloadMultipleInvoicesZipFile(dataArrayToDownload);
      } else {
        NotificationManager.error({
          message: 'notification.error.downloadInvoice',
        });
      }
    } else if (status === ASYNC_STATUS.ERROR) {
      NotificationManager.error({
        message: 'notification.error.downloadInvoice',
      });
    }
  }, [status, value, hasDownloadInvoicePermission]);

  const onCancel = () => setShowModal(false);

  const onDownloadInvoices = useCallback(() => {
    if (hasDownloadInvoicePermission) {
      execute();
    }
  }, [execute, hasDownloadInvoicePermission]);

  const shouldDisableDownloadButton = useMemo(
    () =>
      isLoading ||
      isDownloading ||
      hasEmptyElements(selectedInvoices) ||
      !hasDownloadInvoicePermission,
    [hasDownloadInvoicePermission, isDownloading, isLoading, selectedInvoices]
  );

  const renderFooter = () => (
    <Row justify="space-between">
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <CustomButton
          className="invoices-download-modal__cancel"
          type="ghost"
          onClick={onCancel}
        >
          {t('orders.history.invoiceDownloadModal.cancel')}
        </CustomButton>
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <CustomButton
          className="invoices-download-modal__download"
          onClick={onDownloadInvoices}
          icon={isDownloading && <LoadingOutlined />}
          disabled={shouldDisableDownloadButton}
        >
          {t('orders.history.invoiceDownloadModal.download')}
        </CustomButton>
      </Col>
    </Row>
  );

  return (
    <Modal
      className="invoices-download-modal"
      open={showModal}
      closable={false}
      onCancel={onCancel}
      width={700}
      footer={renderFooter()}
    >
      {renderTitle()}
      {renderOrderNumber()}
      <div className="invoices-download-modal__order-date-reference-number__wrapper">
        {renderOrderDate()}
        {renderReferenceNumber()}
      </div>
      {renderStatus()}
      {renderInvoiceTable()}
    </Modal>
  );
}

DownloadModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  orderNumber: PropTypes.string,
  orderDate: PropTypes.string,
  referenceNumber: PropTypes.string,
  orderStatus: PropTypes.string,
};

DownloadModal.defaultProps = {
  showModal: false,
  setShowModal: () => {},
  orderNumber: '',
  orderDate: 'N/A',
  referenceNumber: 'N/A',
  orderStatus: 'N/A',
};

export default DownloadModal;

import './styles.scss';

import { Modal } from 'antd';
import B2BecLink from 'components/B2BLink';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserId } from 'store/selectors/authenticationSelectors';
import { authenticationActions } from 'store/slices/authenticationSlice';
import { setupActions } from 'store/slices/setupSlice';

const ConfigurationModal = (props) => {
  const { open, onConfirmHandler, title, content } = props;

  return (
    <Modal
      open={open}
      onOk={onConfirmHandler}
      title={title}
      cancelButtonProps={{ style: { display: 'none' } }}
      className="configuration-popup__wrapper"
      okButtonProps={{
        className: 'kaercher-button configuration-popup__confirm-button',
      }}
      {...props}
    >
      <div className="configuration-popup__maincontent">{content}</div>
    </Modal>
  );
};

const SAPDowntimeModal = ({ open, onClose }) => {
  const { t } = useTranslation();

  const onConfirmHandler = () => {
    onClose();
  };

  const modalContent = (
    <>
      <p className="mb-3">{t('sapDownTimePopup.mainContent')}</p>
      <B2BecLink rel="stylesheet" to="/contact-form" onClick={onConfirmHandler}>
        {t('sapDownTimePopup.contactMyKarcherBusinessSupport')}
      </B2BecLink>
    </>
  );

  return (
    <ConfigurationModal
      open={open}
      title={t('sapDownTimePopup.title')}
      content={modalContent}
      okText={t('sapDownTimePopup.ok')}
      onConfirmHandler={onConfirmHandler}
      width={570}
    />
  );
};

const UnauthorizedUserModal = ({ open }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userId = useSelector(selectUserId);

  const handleLogout = () => {
    dispatch(authenticationActions.signOut());
  };

  const modalContent = (
    <>
      <p className="mb-3">{t('invalidUserPopup.unauthorized.mainContent')}</p>
      <B2BecLink to={`/self-registration/${userId}`}>
        {t('invalidUserPopup.unauthorized.supportLink')}
      </B2BecLink>
    </>
  );

  return (
    <ConfigurationModal
      open={open}
      title={t('invalidUserPopup.unauthorized.title')}
      content={modalContent}
      okText={t('logout')}
      onConfirmHandler={handleLogout}
      width={570}
      closable={false}
    />
  );
};

const MissingDataUserModal = ({ open }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(authenticationActions.signOut());
    dispatch(setupActions.hideMissingDataUserModal());
  };

  const modalContent = (
    <p className="mb-3">{t('invalidUserPopup.missingData.mainContent')}</p>
  );

  return (
    <ConfigurationModal
      open={open}
      title={t('invalidUserPopup.missingData.title')}
      content={modalContent}
      okText={t('logout')}
      onConfirmHandler={handleLogout}
      width={570}
      closable={false}
    />
  );
};

const InProgressingUserModal = (props) => {
  const { open } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(authenticationActions.signOut());
  };

  const modalContent = (
    <p className="mb-3">{t('invalidUserPopup.inProgress.mainContent')}</p>
  );

  return (
    <ConfigurationModal
      open={open}
      title={t('invalidUserPopup.inProgress.title')}
      content={modalContent}
      okText={t('logout')}
      onConfirmHandler={handleLogout}
      width={570}
      closable={false}
    />
  );
};

ConfigurationModal.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.node,
  onConfirmHandler: PropTypes.func.isRequired,
};

ConfigurationModal.defaultProps = {
  open: false,
  title: '',
  content: <></>,
};

SAPDowntimeModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

SAPDowntimeModal.defaultProps = {
  open: false,
  onClose: () => {},
};

UnauthorizedUserModal.propTypes = {
  open: PropTypes.bool,
};

UnauthorizedUserModal.defaultProps = {
  open: false,
};

InProgressingUserModal.propTypes = {
  open: PropTypes.bool,
};

InProgressingUserModal.defaultProps = {
  open: false,
};

export default ConfigurationModal;

export {
  SAPDowntimeModal,
  UnauthorizedUserModal,
  InProgressingUserModal,
  MissingDataUserModal,
};

import { Modal } from 'antd';
import B2becButton from 'components/B2becButton';
import B2becQuantity from 'components/B2becQuantity';
import { ChevronLeftIcon } from 'components/Icons';
import { NotificationManager } from 'components/Notification';
import { useMediaQuery } from 'hooks/useMediaQuery';
import BREAKPOINTS from 'libs/constants/breakpoints';
import { isValidArray } from 'libs/utils/array';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserId } from 'store/selectors/authenticationSelectors';
import {
  selectIsAddProductToWishlistModalVisible,
  selectProductDetail,
} from 'store/selectors/wishlistSelectors';
import {
  clearProductDetail,
  hideAddProductToWishlistModal,
  useAddProductToWishlistMutation,
  useCreateAndAddProductToWishlistMutation,
  useFetchWishlistsQuery,
} from 'store/slices/wishlistSlice';

import PermissionWrapper from '../../../HOCs/permissionWrapper';
import { MODULE_WISHLIST_RIGHTS } from '../../../libs/constants/modulerights';
import { NewWishlistNameInput } from '../NewWishlistNameInput';
import styles from './AddProductToWishlistModal.module.scss';
import { WishlistItem } from './WishlistItem';

const skeletons = Array(3)
  .fill(0)
  .map((_, index) => <WishlistItem.Loading key={index} />);

function ProductToBeAddedToWishlist({
  materialName,
  materialNumber,
  img,
  quantity,
  onChangeQuantity,
}) {
  return (
    <div className={styles.product}>
      <div className={styles.image}>
        <img src={img} alt={materialName} />
      </div>
      <div className={styles.content}>
        <span className={styles['material-name']}>{materialName}</span>
        <span className={styles['sub-information']}>{materialNumber}</span>
      </div>
      <div className={styles.action}>
        <B2becQuantity
          inputClassName={styles.quantity}
          initialQuantity={quantity}
          onChange={onChangeQuantity}
          minIntDigits={1}
        />
      </div>
    </div>
  );
}

function ConfirmationModal({ isExecuting, open, onOk, onCancel }) {
  const { t } = useTranslation();

  return (
    <Modal
      closable={false}
      title={t('wishlist.modal.addProduct.confirmTitle')}
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      footer={[
        <B2becButton
          key="confirmation-modal-cancel-button"
          className={styles['confirmation-modal__button']}
          ghost
          disabled={isExecuting}
          onClick={onCancel}
        >
          {t('buttonTexts.cancel')}
        </B2becButton>,
        <B2becButton
          key="confirmation-modal-ok-button"
          className={styles['confirmation-modal__button']}
          onClick={onOk}
          loading={isExecuting}
        >
          {t('buttonTexts.save')}
        </B2becButton>,
      ]}
    >
      <p>{t('wishlist.modal.addProduct.confirmContent')}</p>
    </Modal>
  );
}

export default function AddProductToWishListModal() {
  // Remove this ref when upgrading to React 18
  const isMountedRef = useRef(true);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [addProductToWishlist] = useAddProductToWishlistMutation();
  const [createAndAddProductToWishlist] =
    useCreateAndAddProductToWishlistMutation();
  const isLargerThan992 = useMediaQuery(`(min-width:${BREAKPOINTS.lg}px)`);
  const isAddProductToWishlistModalVisible = useSelector(
    selectIsAddProductToWishlistModalVisible
  );
  const { materialName, materialNumber, img } =
    useSelector(selectProductDetail);
  const [newWishlistName, setNewWishlistName] = useState('');
  const [shouldShowConfirmModal, setShouldShowConfirmModal] = useState(false);
  const [isExecuting, setIsExecuting] = useState(false);
  const [loadingIds, setLoadingIds] = useState({});
  const [quantity, setQuantity] = useState(1);
  const canSave = newWishlistName.trim().length > 0;
  const userId = useSelector(selectUserId);

  const {
    data: wishlists,
    isLoading: isFetchingWishlists,
    isSuccess: isFetchWishlistsSuccess,
  } = useFetchWishlistsQuery({
    ownerId: userId,
  });

  const handleChangeQuantity = (newQuantity) => {
    setQuantity(newQuantity);
  };

  const handleChangeNewWishlistName = (e) => {
    setNewWishlistName(e.target.value);
  };

  const handleCancel = () => {
    dispatch(clearProductDetail());
    dispatch(hideAddProductToWishlistModal());
  };

  const handleSave = async () => {
    if (canSave) {
      try {
        setIsExecuting(true);

        const { error } = await createAndAddProductToWishlist({
          wishlistName: newWishlistName,
          ownerId: userId,
          materialName,
          materialNumber,
          quantity,
        });

        if (error) {
          throw error;
        }

        NotificationManager.success({
          message: 'notification.success.addProductToWishlist',
        });

        dispatch(clearProductDetail());
        dispatch(hideAddProductToWishlistModal());
      } catch (error) {
        NotificationManager.error({
          message: 'notification.error.createWishlist',
        });
        console.error('failed to create new wishlist ', error);
      } finally {
        if (isMountedRef.current) {
          setShouldShowConfirmModal(false);
          setIsExecuting(false);
        }
      }
    }
  };

  const handleAdd = async ({ ownerId, wishlistId }) => {
    try {
      setLoadingIds((prev) => ({
        ...prev,
        [wishlistId]: true,
      }));
      const { error } = await addProductToWishlist({
        ownerId,
        wishlistId,
        materialName,
        materialNumber,
        quantity,
      });

      if (error) {
        throw error;
      }

      NotificationManager.success({
        message: 'notification.success.addProductToWishlist',
      });

      dispatch(clearProductDetail());
      dispatch(hideAddProductToWishlistModal());
    } catch (error) {
      const { data, status } = error || {};
      let message = 'notification.error.addProductToWishlist';

      if (status === 500 && data?.includes('has already been in Wishlist')) {
        message = 'notification.error.productIsAlreadyInWishlist';
      }

      NotificationManager.error({
        message,
      });
    } finally {
      if (isMountedRef.current) {
        setLoadingIds((prev) => ({
          ...prev,
          [wishlistId]: false,
        }));
      }
    }
  };

  const headerSection = (
    <div className={styles['modal__title-wrapper']}>
      {!isLargerThan992 && (
        <button
          className={`button-as-link ${styles['modal__close-button']}`}
          onClick={handleCancel}
          type="button"
        >
          <ChevronLeftIcon />
        </button>
      )}
      <h1 className={styles.modal__title}>
        {t('wishlist.modal.addProduct.title')}
      </h1>
    </div>
  );

  const wishlistsSection = (
    <>
      {isFetchingWishlists ? skeletons : null}
      {isFetchWishlistsSuccess && isValidArray(wishlists) ? (
        <ul className={styles.wishlists}>
          {wishlists.map(
            ({ wishlistId, wishlistName, ownerId, totalProduct }) => {
              return (
                <li key={wishlistId} className={styles.wishlists__item}>
                  <WishlistItem
                    wishlistId={wishlistId}
                    wishlistName={wishlistName}
                    totalProduct={totalProduct}
                    loadingIds={loadingIds}
                    onClick={() => handleAdd({ ownerId, wishlistId })}
                  />
                </li>
              );
            }
          )}
        </ul>
      ) : null}
    </>
  );

  const newWishlistSection = (
    <>
      <span className={styles['new-wishlist__header']}>
        {t('wishlist.createNewWishlist')}
      </span>
      <NewWishlistNameInput
        placeholder={t('wishlist.placeholder.newWishlistName')}
        value={newWishlistName}
        onChange={handleChangeNewWishlistName}
      />
    </>
  );

  useEffect(
    () => () => {
      isMountedRef.current = false;
    },
    []
  );

  return (
    <>
      <Modal
        centered
        className={styles.modal}
        closable={false}
        destroyOnClose
        footer={null}
        width={isLargerThan992 ? 540 : '100%'}
        open={isAddProductToWishlistModalVisible}
        onCancel={handleCancel}
      >
        {headerSection}
        <ProductToBeAddedToWishlist
          materialName={materialName}
          materialNumber={materialNumber}
          img={img}
          quantity={quantity}
          onChangeQuantity={handleChangeQuantity}
        />
        {wishlistsSection}
        <PermissionWrapper permission={MODULE_WISHLIST_RIGHTS.ADD_WISHLIST}>
          <div className={styles['break-line']} />
          {newWishlistSection}
          <B2becButton
            className={styles['modal__action-button']}
            disabled={!canSave}
            onClick={() => setShouldShowConfirmModal(true)}
          >
            {t('buttonTexts.save')}
          </B2becButton>
        </PermissionWrapper>
      </Modal>
      {shouldShowConfirmModal && (
        <ConfirmationModal
          isExecuting={isExecuting}
          open={shouldShowConfirmModal}
          onOk={handleSave}
          onCancel={handleCancel}
        />
      )}
    </>
  );
}

ProductToBeAddedToWishlist.propTypes = {
  materialNumber: PropTypes.string,
  materialName: PropTypes.string,
  img: PropTypes.string,
  quantity: PropTypes.number,
  onChangeQuantity: PropTypes.func.isRequired,
};

ProductToBeAddedToWishlist.defaultProps = {
  materialNumber: '',
  materialName: '',
  img: '',
  quantity: 1,
};

ConfirmationModal.propTypes = {
  isExecuting: PropTypes.bool.isRequired,
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

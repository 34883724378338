import { Dropdown } from 'antd';
import { ChevronDownIcon, ChevronUpIcon } from 'components/Icons';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './B2BecSorting.module.scss';
import { defaultSortOptions, getSortOption } from './sortOptions';

const B2becSorting = ({ options, sortOption, onSort }) => {
  const { t } = useTranslation();
  const [selectedKey, setSelectedKey] = useState(
    sortOption?.sortOrder !== 0 ? sortOption?.key : null
  );

  const handleSort = useCallback(
    ({ key }) => {
      const sort = getSortOption(key);

      setSelectedKey(sort.key);
      onSort(sort);
    },
    [onSort]
  );

  const items = options
    .filter(({ sortOrder }) => sortOrder !== 0)
    .map(({ key, text }) => ({
      key,
      label: t(text),
    }));

  return (
    <Dropdown
      menu={{
        items,
        onClick: handleSort,
        selectedKeys: [`${selectedKey}`],
      }}
    >
      <button
        type="button"
        onClick={(e) => e.preventDefault()}
        className={styles.button}
      >
        <div className={styles.iconsWrapper}>
          <ChevronUpIcon />
          <ChevronDownIcon />
        </div>
        <span>{t('sorting.button.sort')}</span>
      </button>
    </Dropdown>
  );
};

export default B2becSorting;

B2becSorting.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.string,
    })
  ),
  sortOption: PropTypes.shape({
    key: PropTypes.string,
    text: PropTypes.string,
    sortOrder: PropTypes.number,
  }),
  onSort: PropTypes.func,
};

B2becSorting.defaultProps = {
  options: defaultSortOptions,
  sortOption: {},
  onSort: () => {},
};

import { Menu } from 'antd';
import React, { memo } from 'react';

import { useMenu } from './useMenu';

function SidebarMenu() {
  const { items, currentKey, onMenuItemClick } = useMenu();

  return (
    <Menu
      className="sidebar__menu"
      mode="inline"
      onClick={onMenuItemClick}
      selectedKeys={[currentKey]}
      items={items}
    />
  );
}

export default memo(SidebarMenu);

import { Col, Form } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectAssignableCountryRolesList } from 'store/selectors/roleSelector';

import { isValidArray } from '../../libs/utils/array';
import CustomButton from '../CustomButton';
import { DeleteIconBorderless, PlusIcon } from '../Icons';
import styles from './B2becAssignRole.module.scss';
import AssignRoles from './components/AssignRoles';

const B2becAssignRole = () => {
  const assignableCountryRolesList = useSelector(
    selectAssignableCountryRolesList
  );
  const { t } = useTranslation();

  return (
    <Form.List name="countryRoles" className="b2b-user-form__assign-roles">
      {(fields, { add, remove }, { errors }) => (
        <>
          {fields.map((field) => {
            return (
              <Col span={24} key={`countryRoles${field.key}`}>
                <Form.Item
                  {...field}
                  label={t('form.label.assignRoles')}
                  validateTrigger={['onSubmit']}
                  rules={[
                    {
                      validator: (_, value) => {
                        if (!isValidArray(value?.roles)) {
                          return Promise.reject(t('errors.countryRoles'));
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <AssignRoles />
                </Form.Item>
                <DeleteIconBorderless
                  onClick={() => {
                    remove(field.name);
                  }}
                  className={styles.minusButton}
                />
              </Col>
            );
          })}
          <Col span={24}>
            <Form.ErrorList errors={errors} />
          </Col>
          {((isValidArray(assignableCountryRolesList) &&
            fields?.length === 0) ||
            (isValidArray(assignableCountryRolesList, 1) &&
              fields?.length < assignableCountryRolesList?.length)) && (
            <Col span={24}>
              <Form.Item>
                <CustomButton
                  buttonSize="small"
                  type="ghost"
                  className={styles.addButton}
                  onClick={() => add()}
                >
                  <PlusIcon /> {t('form.label.addRole')}
                </CustomButton>
              </Form.Item>
            </Col>
          )}
        </>
      )}
    </Form.List>
  );
};
export default B2becAssignRole;

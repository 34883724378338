/* eslint-disable react-hooks/exhaustive-deps */
import './styles.scss';

import { AutoComplete, Divider, Input, List } from 'antd';
import { ChevronRightIcon, CloseIcon, SearchIcon } from 'components/Icons';
import { useSearchContext } from 'contexts/search';
import useDeviceDetect from 'hooks/useDeviceDetect';
import { linkGenerator } from 'libs/utils/language';
import { formatMaterialID } from 'libs/utils/material-number';
import React, { memo, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { trackOnSiteSearch } from 'store/slices/adobeAnalyticSlice';
import { getSearchSuggestions, setSearchTerm } from 'store/slices/searchSlice';

import { SHOP_CATEGORY_TYPE } from '../../libs/constants';
import { isValidArray } from '../../libs/utils/array';
import { selectSubmenuProductCategories } from '../../store/selectors/categorySelector';
import {
  selectIsSuggestionsFetching,
  selectSearchTerm,
  selectSuggestions,
} from '../../store/selectors/searchSelector';
import B2BecLink from '../B2BLink';
import CustomButton from '../CustomButton';
import {
  generateSuggestionItemsLoading,
  generateSuggestionItemsTransparent,
} from './dummyData';
import SuggestionCategoryItem from './SuggestionCategoryItem';
import SuggestionItem from './SuggestionItem';

const { Search } = Input;

const listOfCategoryDegergent = [
  {
    categoryId: 'accessories',
    categoryName: 'productSubCategoryOverview.accessories.title',
    type: 'accessories',
    menuImages: [
      {
        url: 'https://s1.kaercher-media.com/media/image/selection/1550/d3/accessory.webp',
        type: 'thumb',
      },
    ],
  },
  {
    categoryId: 'detergents',
    categoryName: 'productSubCategoryOverview.detergents.title',
    type: 'detergents',
    menuImages: [
      {
        url: 'https://s1.kaercher-media.com/media/image/selection/46310/d3/detergents.webp',
        type: 'thumb',
      },
    ],
  },
];

const suggestionCategoryKecIdList = [35452, 35427, 35465, 71458];

const Autocomplete = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { isSearchFocused, setIsSearchFocused } = useSearchContext();
  const { isIpadLandscape } = useDeviceDetect();

  const productCategories = useSelector(selectSubmenuProductCategories);
  const searchTerm = useSelector(selectSearchTerm);
  const suggestions = useSelector(selectSuggestions);
  const isSuggestionsFetching = useSelector(selectIsSuggestionsFetching);

  const searchInputRef = useRef();
  const timeoutId = useRef();

  const analyzeSearchHandler = useCallback(
    (totalResult = 0) => {
      dispatch(
        trackOnSiteSearch({
          onsiteSearchTerm: searchTerm,
          onsiteSearchResult: totalResult,
        })
      );
    },
    [dispatch, searchTerm]
  );

  const suggestionCategories = useMemo(() => {
    const tempArray = [...listOfCategoryDegergent];
    if (isValidArray(productCategories)) {
      productCategories.forEach((category) => {
        const subCategories = category?.subCategories;
        if (isValidArray(subCategories)) {
          subCategories.forEach((subCategory) => {
            if (suggestionCategoryKecIdList.includes(subCategory?.kecId))
              tempArray.unshift({
                ...subCategory,
                type: SHOP_CATEGORY_TYPE.MACHINES,
              });
          });
        }
      });
    }

    return tempArray;
  }, [productCategories]);

  const renderSearchNotFoundContent = useCallback(
    () => (
      <p className="search-not-found__text">
        {t('searchNoResults')} <q>{searchTerm}</q>
      </p>
    ),
    [t, searchTerm]
  );

  const onFocusSearch = useCallback(() => {
    setIsSearchFocused(true);
  }, []);

  const onBlurSearch = useCallback(() => {
    setIsSearchFocused(false);
  }, []);

  const onBlurSearchAndInput = useCallback(() => {
    onBlurSearch();

    setTimeout(() => {
      if (searchInputRef.current) {
        searchInputRef.current.blur();
      }
    }, 0);
  }, []);

  const onSearch = useCallback(
    (newSearchTerm) => {
      if (newSearchTerm) {
        if (isSearchFocused) {
          onBlurSearchAndInput();
        }

        history.push(linkGenerator(`/search-results/${newSearchTerm}`));
      }
    },
    [history, isSearchFocused, onBlurSearchAndInput]
  );

  const onSeeAllResults = useCallback(() => {
    onBlurSearchAndInput();

    history.push(linkGenerator(`/search-results/${searchTerm}`));
  }, [history, searchTerm, onBlurSearchAndInput]);

  const options = useMemo(() => {
    if (isSuggestionsFetching) {
      return generateSuggestionItemsLoading(isIpadLandscape);
    }

    if (searchTerm === '') {
      // For Desktop view only:
      // When user focus on the search input => show empty space dropdown
      // on left column to make Specials For You on right column visible
      // while no searchKeyword is entered
      return generateSuggestionItemsTransparent(isIpadLandscape);
    }

    return suggestions.map(({ materialNumber, name, imageUrl }) => {
      const formattedMaterialNumber = formatMaterialID(
        materialNumber,
        materialNumber?.length
      );

      return {
        value: formattedMaterialNumber,
        label: (
          <SuggestionItem
            name={name}
            imageUrl={imageUrl}
            materialNumber={formattedMaterialNumber}
            isIpadLandscape={isIpadLandscape}
          />
        ),
      };
    });
  }, [isSuggestionsFetching, isIpadLandscape, searchTerm, suggestions]);

  const onSuggestionCategoryClick = useCallback(
    (categoryId, type) => {
      analyzeSearchHandler();
      onBlurSearchAndInput();
      if (categoryId) {
        const link =
          type === SHOP_CATEGORY_TYPE.MACHINES
            ? `/category/${categoryId}`
            : `/categories/${categoryId}`;
        history.push(linkGenerator(link));
      }
    },
    [onBlurSearchAndInput, history, searchTerm]
  );

  const handleSelectOption = useCallback(
    (value, option) => {
      const { name } = option?.label?.props;

      if (value && typeof value === 'string') {
        analyzeSearchHandler();
        onBlurSearchAndInput();
        dispatch(setSearchTerm(name));

        history.push(linkGenerator(`/product/${value}`));
      }
    },
    [dispatch, history, onBlurSearchAndInput, searchTerm]
  );

  const onSearchChange = useCallback(
    // Wait for 300ms after users stop typing
    // then dispatch to get the suggestions
    (newSearchTerm) => {
      clearTimeout(timeoutId.current);

      dispatch(setSearchTerm(newSearchTerm));

      timeoutId.current = setTimeout(() => {
        if (newSearchTerm.length >= 2) {
          dispatch(getSearchSuggestions(newSearchTerm));
        }
      }, 300);
    },
    [dispatch]
  );

  const onInputKeyDown = useCallback(
    (e) => {
      // Check if users press Enter
      // then blur the search
      if (e.keyCode === 27) {
        onBlurSearchAndInput();
        e.preventDefault();
      }
    },
    [onBlurSearchAndInput]
  );

  const customMenu = (menu) => {
    return (
      <div role="button" tabIndex="0" onMouseDown={(e) => e.preventDefault()}>
        <div className="search__dropdown__wrapper">
          {isIpadLandscape ? (
            options.every((item) => !item?.label?.props?.isTransparent) && (
              <div className="search__dropdown__suggestion">{menu}</div>
            )
          ) : (
            <div className="search__dropdown__suggestion">{menu}</div>
          )}
          {(!isIpadLandscape || searchTerm === '') && (
            <div className="search__dropdown__specials-for-you__wrapper">
              {!isIpadLandscape && (
                <div className="search__dropdown__specials-for-you__divider">
                  <Divider type="vertical" />
                </div>
              )}
              <div className="search__dropdown__product-categories__wrapper--inner">
                <div className="search__dropdown__product-categories__title">
                  {t('myWorkspace.productCategories.title')}
                </div>
                <div className="search__dropdown__product-categories__item__list">
                  <List
                    dataSource={suggestionCategories}
                    renderItem={(category) => (
                      <List.Item
                        key={category?.categoryId}
                        className="search__dropdown__product-categories__item"
                      >
                        <SuggestionCategoryItem
                          categoryId={category?.categoryId}
                          categoryName={category?.categoryName}
                          type={category?.type}
                          menuImages={category?.menuImages}
                          onClick={onSuggestionCategoryClick}
                        />
                      </List.Item>
                    )}
                  />
                </div>
                <p className="search__dropdown__product-categories__item__all-categories-link">
                  <B2BecLink to="/categories">
                    <ChevronRightIcon
                      style={{ width: 13, height: 13, marginRight: 10 }}
                    />
                    <span>
                      {t('myWorkspace.productCategories.seeAllCategories')}
                    </span>
                  </B2BecLink>
                </p>
              </div>
            </div>
          )}
        </div>
        {!isIpadLandscape &&
        searchTerm.trim() !== '' &&
        !isSuggestionsFetching &&
        options.length > 0 ? (
          <div className="see-all-results__wrapper">
            <CustomButton
              className="see-all-results__button fontBold"
              onMouseDown={(e) => e.preventDefault()}
              onClick={onSeeAllResults}
            >
              {t('seeAllResutls')}
            </CustomButton>
          </div>
        ) : null}
      </div>
    );
  };

  const searchNotFound =
    options.length === 0 && searchTerm !== '' && !isSuggestionsFetching;

  return (
    <div className="search__container" id="search__wrapper">
      <AutoComplete
        options={options}
        dropdownRender={customMenu}
        popupClassName="suggestion-item-ant-select-item-option--override"
        defaultActiveFirstOption={false}
        notFoundContent={searchNotFound ? renderSearchNotFoundContent() : null}
        onBlur={onBlurSearch}
        onChange={onSearchChange}
        onFocus={onFocusSearch}
        onSelect={handleSelectOption}
        onInputKeyDown={onInputKeyDown}
        value={searchTerm}
        listHeight={600}
        getPopupContainer={() => document.getElementById('search__wrapper')}
        open={isSearchFocused}
        autoFocus={isIpadLandscape && isSearchFocused}
        showArrow={false}
      >
        <Search
          size="large"
          type="search"
          placeholder={t('searchPlaceholder')}
          enterButton={
            isIpadLandscape ? <CloseIcon /> : t('buttonTexts.search')
          }
          prefix={<SearchIcon />}
          onSearch={isIpadLandscape ? onBlurSearchAndInput : onSearch}
          ref={searchInputRef}
        />
      </AutoComplete>
    </div>
  );
};

export default memo(Autocomplete);

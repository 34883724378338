import { NotificationManager } from 'components/Notification';
import { logErrors } from 'libs/utils/common';
import { linkGenerator } from 'libs/utils/language';
import { removeKeys } from 'libs/utils/object';
import { call, getContext, put, select, takeLatest } from 'redux-saga/effects';
import { fapiRoleService } from 'services/adminFapi';

import { isValidArray } from '../../libs/utils/array';
import {
  createRole,
  createRoleFailed,
  createRoleSuccess,
  duplicateRoleById,
  duplicateRoleByIdFailed,
  duplicateRoleByIdSuccess,
  getAssignableRolesByUserId,
  getAssignableRolesByUserIdFailed,
  getAssignableRolesByUserIdSuccess,
  getAssignedRolesByUserId,
  getAssignedRolesByUserIdFailed,
  getAssignedRolesByUserIdSuccess,
  getHierarchy,
  getHierarchyFailed,
  getHierarchySuccess,
  getListParentRole,
  getListParentRoleFailed,
  getListParentRoleSuccess,
  getRoleDetails,
  getRoleDetailsFailed,
  getRoleDetailsSuccess,
  getRolesList,
  getRolesListFailed,
  getRolesListSuccess,
  getUserRoles,
  getUserRolesFailed,
  getUserRolesSuccess,
  setFetchingId,
  updateRoleDetail,
  updateRoleDetailFailed,
  updateRoleDetailSuccess,
} from '../slices/roleSlice';

function* getHierarchySaga() {
  try {
    const { data } = yield call(fapiRoleService.getParentRolesList);

    yield put(getHierarchySuccess(data));
  } catch (error) {
    yield put(getHierarchyFailed());
  }
}

function* getRoleDetailsSaga({ payload }) {
  try {
    const { data } = yield call(fapiRoleService.getRoleDetails, payload);

    yield put(getRoleDetailsSuccess(data));
  } catch (error) {
    yield put(getRoleDetailsFailed());
  }
}

function* getRolesListSaga() {
  try {
    const { data } = yield call(fapiRoleService.getRolesList);
    const mappedRolesList = data.map(({ parentRole, ...role }) => {
      return {
        ...role,
        parentRole: parentRole.hierarchyId,
        parentRoleText: parentRole.hierarchyPath,
      };
    });
    yield put(getRolesListSuccess(mappedRolesList));
  } catch (error) {
    yield put(getRolesListFailed());
  }
}

function* getListParentRoleSaga() {
  try {
    const { data } = yield call(fapiRoleService.getParentRolesList);
    const listRole = data.map(
      ({ hierarchyId: value, hierarchyPath: label }) => {
        return {
          value,
          label,
        };
      }
    );
    yield put(getListParentRoleSuccess(listRole));
  } catch (error) {
    yield put(getListParentRoleFailed());
  }
}

function splitRightsBySlash(data) {
  const rights = data?.rights?.map((right) => {
    const splitRight = right?.split('/');

    if (isValidArray(splitRight) && splitRight?.length > 1) {
      return splitRight[1];
    }

    return null;
  });

  return {
    ...data,
    rights,
  };
}

function* createRoleSaga({ payload }) {
  try {
    const { history } = yield getContext('dependencies');
    const modifiedData = splitRightsBySlash(payload);
    const { data } = yield call(fapiRoleService.createRole, modifiedData);

    yield put(createRoleSuccess({ roleId: data, ...payload }));
    yield put(getListParentRole());

    NotificationManager.success({
      message: 'notification.success.createRole',
    });

    history.push(linkGenerator('/roles-management'));
  } catch (error) {
    yield put(createRoleFailed());
    NotificationManager.error({
      message: 'notification.error.createRole',
    });
  }
}

function* updateRoleSaga({ payload }) {
  try {
    const { history } = yield getContext('dependencies');

    yield call(fapiRoleService.updateRole, payload);
    yield put(updateRoleDetailSuccess(payload));
    yield put(getListParentRole());

    history.push(linkGenerator('/roles-management'));
  } catch (error) {
    yield put(updateRoleDetailFailed());
    NotificationManager.error({
      message: 'notification.error.updateRole',
    });
  }
}

function* duplicateRoleByIdSaga({ payload }) {
  try {
    yield put(setFetchingId({ roleId: payload, isFetching: true }));

    const { history } = yield getContext('dependencies');
    const response = yield call(fapiRoleService.getRoleDetails, payload);
    const modifiedData = removeKeys(
      ['roleName', 'createdAt', 'createdBy'],
      response.data
    );

    yield put(duplicateRoleByIdSuccess(modifiedData));

    history.push(linkGenerator('/roles-management/create-role'));
  } catch (error) {
    yield put(duplicateRoleByIdFailed());
    yield NotificationManager.error({
      message: 'notification.error.duplicateRole',
    });
    console.error(error);
  } finally {
    yield put(setFetchingId({ roleId: payload, isFetching: false }));
  }
}

const processSaleOrgs = (saleOrgs) => {
  return saleOrgs.reduce((acc, saleOrg) => {
    const updatedAcc = { ...acc };
    updatedAcc[saleOrg.code] = saleOrg.name;
    return updatedAcc;
  }, {});
};

function* processRoleAndSaleOrgs(data) {
  const result = { saleOrgs: [], assignableRolesBySaleOrgs: [] };

  if (isValidArray(data)) {
    const availableSaleOrgs = yield select(
      (state) => state.config.availableSaleOrgs
    );
    const fallbackCountry = yield select(
      (state) => state.user.userDetails?.userInfo?.countryId
    );
    const saleOrgLookup = processSaleOrgs(availableSaleOrgs);

    data.forEach((item) => {
      const saleOrg =
        saleOrgLookup[item.country] ?? saleOrgLookup[fallbackCountry];

      if (saleOrg) {
        const country = item.country ?? fallbackCountry;

        result.saleOrgs.push({
          value: country,
          label: saleOrg,
        });

        const assignableRoles =
          item?.assignableRoles?.map((role) => ({
            value: role.roleId,
            label: role.roleName,
          })) ?? [];

        result.assignableRolesBySaleOrgs.push({
          country,
          roles: assignableRoles,
        });
      }
    });
  }
  return result;
}

function* getAssignableRolesByUserIdSaga({ payload }) {
  try {
    const { data } = yield call(
      fapiRoleService.getAssignableRolesByUserId,
      payload
    );
    const assignableRoles = yield call(processRoleAndSaleOrgs, data);

    yield put(getAssignableRolesByUserIdSuccess(assignableRoles));
  } catch (error) {
    yield put(getAssignableRolesByUserIdFailed());
    NotificationManager.error({
      message: 'notification.error.getAssignableCountryRoleList',
    });
  }
}

function* getAssignedRolesByUserIdSaga({ payload }) {
  try {
    const { data } = yield call(
      fapiRoleService.getAssignedRolesByUserId,
      payload
    );

    const assignedRoles = {
      assignedRoles: data?.roles,
      userId: payload,
    };

    yield put(getAssignedRolesByUserIdSuccess(assignedRoles));
  } catch (error) {
    yield put(getAssignedRolesByUserIdFailed());
    NotificationManager.error({
      message: 'notification.error.getAssignedRoles',
    });
  }
}

function* getUserRolesSaga() {
  try {
    const { data } = yield call(fapiRoleService.getCurrentUserRoles);

    // Collect all unique rights from all roles of response
    const allRights = data?.roles?.flatMap((role) => role?.rights || []);
    const uniqueRights = [...new Set(allRights)];

    yield put(getUserRolesSuccess(uniqueRights));
  } catch (error) {
    yield put(getUserRolesFailed());
    logErrors(error);
  }
}

export default function* roleSaga() {
  yield takeLatest(getHierarchy.type, getHierarchySaga);
  yield takeLatest(getRoleDetails.type, getRoleDetailsSaga);
  yield takeLatest(getRolesList.type, getRolesListSaga);
  yield takeLatest(getListParentRole.type, getListParentRoleSaga);
  yield takeLatest(createRole.type, createRoleSaga);
  yield takeLatest(updateRoleDetail.type, updateRoleSaga);
  yield takeLatest(duplicateRoleById.type, duplicateRoleByIdSaga);
  yield takeLatest(
    getAssignableRolesByUserId.type,
    getAssignableRolesByUserIdSaga
  );
  yield takeLatest(getUserRoles.type, getUserRolesSaga);
  yield takeLatest(getAssignedRolesByUserId.type, getAssignedRolesByUserIdSaga);
}

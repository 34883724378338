import '../../components/mobileStyles.scss';

import { Drawer } from 'antd';
import { ReactComponent as RightArrow } from 'assets/icons/chevron-right.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import HeaderLogoSection from 'components/Header/components';
import BackButton from 'components/Header/Submenu/Mobile/components/BackButton';
import { SERVICE_ENTRY } from 'libs/constants';
import { linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setSelectedMachineInfo } from 'store/slices/serviceCaseSlice';

import ServiceItem from './ServiceItem';
import ServiceTitle from './ServiceTitle';

const ServiceSubmenuMobile = (props) => {
  const { btnName, onClose, onFocusSearch } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [isShowServices, setShowServices] = useState(false);

  const closeDrawer = useCallback(() => {
    onClose();
    setShowServices(false);
  }, [onClose, setShowServices]);

  const handleRequestService = useCallback(
    (serviceEntry, path) => {
      dispatch(
        setSelectedMachineInfo({
          serviceEntry,
          isChangedService: true,
        })
      );
      closeDrawer();
      history.push(linkGenerator(path));
    },
    [dispatch, history, closeDrawer]
  );

  const serviceRequests = [
    {
      name: t('service.headerMenu.requestRepair'),
      handleClick: handleRequestService,
      serviceEntry: SERVICE_ENTRY.REPAIR,
    },
    {
      name: t('service.headerMenu.requestMaintenance'),
      handleClick: handleRequestService,
      serviceEntry: SERVICE_ENTRY.MAINTENANCE,
    },
  ];

  const handleBackButtonClick = useCallback(() => setShowServices(false), []);

  const handleServicePackagesButtonClick = useCallback(() => {
    history.push(linkGenerator('/service/service-packages'));
  }, [history]);

  const renderServicesDrawer = () => (
    <Drawer // Product Categories Drawer (with 2 items Accessories & Detergents at the end)
      className="header-menu-drawer header-menu-drawer__sub-drawer"
      title={
        <HeaderLogoSection
          inBurgerMenu
          onHideDrawerMobile={onClose}
          onFocusSearch={onFocusSearch}
        />
      }
      placement="right"
      open={isShowServices}
      onClose={closeDrawer}
      closable
      closeIcon={<CloseIcon />}
      width={window.innerWidth}
    >
      <BackButton
        name={t('header.drawer.button.back')}
        onClick={handleBackButtonClick}
      />
      <ServiceTitle
        name={t('pageTitle.servicePackages')}
        onClick={handleServicePackagesButtonClick}
      />
      <ServiceTitle name={t('createServiceRequest')} />
      {serviceRequests.map(({ name, handleClick, path, serviceEntry }) => (
        <ServiceItem
          name={name}
          onClick={handleClick}
          path={path}
          serviceEntry={serviceEntry}
          key={`${name} + ${path}`}
        />
      ))}
    </Drawer>
  );

  return (
    <div className="submenu-mobile">
      <button
        className="submenu-mobile__btn"
        type="button"
        onClick={() => setShowServices(true)}
      >
        {btnName}
        <RightArrow />
      </button>
      {renderServicesDrawer()}
    </div>
  );
};

ServiceSubmenuMobile.propTypes = {
  btnName: PropTypes.string,
  onClose: PropTypes.func,
  onFocusSearch: PropTypes.func,
};

ServiceSubmenuMobile.defaultProps = {
  btnName: '',
  onClose: () => {},
  onFocusSearch: () => {},
};

export default ServiceSubmenuMobile;

import { Tag } from 'antd';
import B2becTranslation from 'components/B2becTranslation';
import {
  USER_ACCOUNT_STATUS,
  USER_STATUS_CLASS_NAME,
} from 'libs/constants/user';
import { isValidArray } from 'libs/utils/array';
import { formatDate, getFormatType } from 'libs/utils/formatDate';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

import styles from './UsersTable.module.scss';

export const renderColumns = (
  isLoading,
  onCellClick,
  hasViewUserDetailPermission
) => [
  {
    title: () => (
      <div>
        <B2becTranslation value="userManagement.companyName" />
      </div>
    ),
    dataIndex: 'companyName',
    width: '8%',
    sortDirections: ['ascend', 'descend', 'ascend'],
    defaultSortOrder: 'ascend',
    sorter: true,
    showSorterTooltip: false,
    onCell: ({ userId, invitationStatus }) => ({
      onClick: () =>
        hasViewUserDetailPermission &&
        !isLoading &&
        onCellClick(userId, invitationStatus),
    }),
    render: (text) =>
      isLoading ? (
        <Skeleton />
      ) : (
        <span className="font-weight-medium">{text}</span>
      ),
  },
  {
    title: <B2becTranslation value="userManagement.firstName" />,
    dataIndex: 'firstName',
    width: '8%',
    onCell: ({ userId, invitationStatus }) => ({
      onClick: () =>
        hasViewUserDetailPermission &&
        !isLoading &&
        onCellClick(userId, invitationStatus),
    }),
    render: (text) => (isLoading ? <Skeleton /> : <span>{text}</span>),
  },
  {
    title: <B2becTranslation value="userManagement.lastName" />,
    dataIndex: 'lastName',
    width: '8%',
    onCell: ({ userId, invitationStatus }) => ({
      onClick: () =>
        hasViewUserDetailPermission &&
        !isLoading &&
        onCellClick(userId, invitationStatus),
    }),
    render: (text) => (isLoading ? <Skeleton /> : <span>{text}</span>),
  },
  {
    title: <B2becTranslation value="userManagement.userRoles" />,
    width: '8%',
    dataIndex: 'roles',
    onCell: ({ userId, invitationStatus }) => ({
      onClick: () =>
        hasViewUserDetailPermission &&
        !isLoading &&
        onCellClick(userId, invitationStatus),
    }),
    render: (assignedRoles) =>
      isLoading ? (
        <Skeleton />
      ) : (
        isValidArray(assignedRoles) && (
          <div className={styles.assignedRolesWrapper}>
            {assignedRoles.map((role) => (
              <Tag className={styles.assignedRole} key={role?.roleId}>
                {role?.roleName}
              </Tag>
            ))}
          </div>
        )
      ),
  },
  {
    title: <B2becTranslation value="userManagement.email" />,
    dataIndex: 'email',
    width: '10%',
    onCell: ({ userId, invitationStatus }) => ({
      onClick: () =>
        hasViewUserDetailPermission &&
        !isLoading &&
        onCellClick(userId, invitationStatus),
    }),
    render: (text) =>
      isLoading ? (
        <Skeleton />
      ) : (
        <span className="font-weight-medium">{text}</span>
      ),
  },
  {
    title: <B2becTranslation value="userManagement.sapCustomerNumber" />,
    dataIndex: 'sapCustomerNumber',
    width: '8%',
    onCell: ({ userId, invitationStatus }) => ({
      onClick: () =>
        hasViewUserDetailPermission &&
        !isLoading &&
        onCellClick(userId, invitationStatus),
    }),
    render: (text) =>
      isLoading ? (
        <Skeleton />
      ) : (
        <span className="font-weight-medium">{text}</span>
      ),
  },
  {
    title: <B2becTranslation value="userManagement.lastLogin" />,
    dataIndex: 'lastLogin',
    width: '8%',
    onCell: ({ userId, invitationStatus }) => ({
      onClick: () =>
        hasViewUserDetailPermission &&
        !isLoading &&
        onCellClick(userId, invitationStatus),
    }),
    render: (text) =>
      isLoading ? (
        <Skeleton />
      ) : (
        <span className={styles.lastLogin}>
          {formatDate(text, getFormatType().default)}
          <br />
          {formatDate(text, getFormatType().timeDetail)}
        </span>
      ),
  },
  {
    title: <B2becTranslation value="userManagement.status" />,
    width: '6%',
    onCell: ({ userId, invitationStatus }) => ({
      onClick: () =>
        hasViewUserDetailPermission &&
        !isLoading &&
        onCellClick(userId, invitationStatus),
    }),
    render: (_, { status, invitationStatus }) => {
      /* if INVITATION STATUS is available, use INVITATION STATUS
       * else use normal STATUS
       * if STATUS is not available or STATUS === 'Open' (value: null, unauthorized, open)
       * set it to be 'Unauthorized'
       */
      let userStatus = invitationStatus || status;

      if (!status || (status && status === USER_ACCOUNT_STATUS.OPEN)) {
        userStatus = USER_ACCOUNT_STATUS.UN_AUTHORIZED;
      }

      return isLoading ? (
        <Skeleton />
      ) : (
        <div
          className={`${styles.userStatus} ${
            styles[USER_STATUS_CLASS_NAME[userStatus]]
          }`}
        >
          <B2becTranslation value={`userManagement.user${userStatus}`} />
        </div>
      );
    },
  },
];

import { DatePicker } from 'antd';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { selectDateFormat } from 'store/selectors/configSelector';

import styles from './DatePeriod.module.scss';

const { RangePicker } = DatePicker;

const disableDate = (current) => {
  return current && current > moment().endOf('day');
};

const RenderDatePeriod = React.memo(({ onDateChange }) => {
  const dateFormat = useSelector(selectDateFormat);
  if (!dateFormat) {
    return <Skeleton style={{ height: 42, width: 280 }} />;
  }

  return (
    <RangePicker
      className={styles.picker}
      popupClassName={styles.dropdown}
      disabledDate={disableDate}
      separator="|"
      size="large"
      getPopupContainer={(trigger) => trigger}
      placeholder={[dateFormat, dateFormat]}
      onChange={onDateChange}
    />
  );
});

export const DatePeriod = (props) => {
  const { t } = useTranslation();

  return (
    <div>
      <span className={styles.title}>{t('filter.datePeriod')}</span>
      <div className={styles.datePeriodWrapper}>
        <RenderDatePeriod {...props} />
      </div>
    </div>
  );
};
